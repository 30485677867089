
import { computed, defineComponent, ref, watch } from 'vue'

import AsBanner from '@web/components/AsBanner.vue'

export default defineComponent({
  name: 'AsBannerInsteadVideo',
  props: {
    banner: {
      type: Object as () => Banner,
      required: true
    },
    position: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      required: true
    },
    platform: {
      type: String,
      required: true
    }
  },
  components: {
    AsBanner
  },
  emits: ['ended'],
  setup (props, ctx) {
    const firstPlay = ref(true)
    const showSkipBtn = ref(false)
    const seconds = ref(15)
    const secondsForSkip = ref(5)
    const timerId = ref<number | null>(null)

    const formattedTime = computed(() => {
      if (String(seconds.value).length > 1) {
        return `00:${seconds.value}`
      }
      return `00:0${seconds.value}`
    })
    const isShowBanner = computed(() => seconds.value > 0)

    function pauseTimer () {
      if (timerId.value) {
        clearInterval(timerId.value)
      }
    }

    function startTimer () {
      timerId.value = setInterval(
        () => {
          seconds.value = seconds.value - 1
          if (seconds.value <= 0) {
            pauseTimer()
          }

          secondsForSkip.value = secondsForSkip.value - 1
          if (secondsForSkip.value <= 0) {
            showSkipBtn.value = true
          }
        },
        1000
      )
    }

    function handlerContinue () {
      seconds.value = 0
      pauseTimer()
    }

    function handlerClick (e) {
      if (firstPlay.value) {
        e.preventDefault()
        e.stopPropagation()

        firstPlay.value = false
        startTimer()
      }
    }

    watch(seconds, (newVal) => {
      if (!newVal) {
        ctx.emit('ended')
      }
    })

    return {
      firstPlay,
      seconds,
      formattedTime,
      isShowBanner,
      showSkipBtn,
      handlerClick,
      pauseTimer,
      startTimer,
      handlerContinue
    }
  }
})
