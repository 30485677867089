export default function useLesson () {
  return {
    time (duration: number): string {
      const format = (n: number) => String(n).padStart(2, '0')
      const hours = Math.floor(duration / 1000 / 60 / 60)
      const minutes = format(Math.floor(duration / 1000 / 60 % 60))
      const seconds = format(Math.floor(duration / 1000 % 60))
      const times = hours > 0 ? [hours, minutes, seconds] : [minutes, seconds]
      return times.join(':')
    }
  }
}
