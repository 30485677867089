import videojs from 'video.js'

const Component = videojs.getComponent('Component')

export default class PlayerSettingsMenu extends Component {
  createEl () {
    return videojs.dom.createEl('div', {
      className: 'vjs-settings-menu',
      id: 'vjs-settings-menu'
    })
  }

  render () {
    const _this = this as videojs.Component
    const menuSettingsEl = videojs.dom.createEl('span', { className: 'vjs-btn-settings-menu' })
    _this.el().appendChild(menuSettingsEl)
  }
}
