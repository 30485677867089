/**
 * Фильтр занятий по опциям урока и по опциям выбранным юзером
 *
 * @param {Object[]} courseOptions
 * @param {number} courseOptions[].id
 * @param {string} courseOptions[].type ['boolean', 'enum']
 * @param {Object} courseOptions[].value
 * @param {string} courseOptions[].value.systemName
 *
 * @param {number} trainingOptions[].optionId - courseOption.id
 * @param {number} trainingOptions[].systemName - ['true', 'false', 'notset', 'all', ...]
 *
 * @returns {function(*): boolean}
 */
export default (courseOptions: Readonly<(CourseOptions & { value: TrainingLessonOptionValue })[]>) =>
  (trainingOptions: TrainingOption[]): boolean => {
    let isShow = true

    if (!courseOptions) return true

    trainingOptions.forEach(option => {
      const courseOption = courseOptions.find(courseOption => courseOption.id === option.optionId)

      if (!courseOption) {
        // если фильтра нет в курсе, то урок по этому фильтру не фильтруется
        return true
      }

      const type = courseOption.type
      const value = courseOption.value || {}
      if (
        type === 'boolean' &&
        option.value.systemName !== 'notset' &&
        value.systemName !== option.value.systemName
      ) {
        isShow = false
      }

      if (
        type === 'enum' &&
        option.value.systemName !== 'notset' &&
        value.systemName !== 'all' &&
        value.systemName !== option.value.systemName
      ) {
        isShow = false
      }
    })

    return isShow
  }
