import { ref, onBeforeUnmount } from 'vue'

// Globals
import { IN_BROWSER, SUPPORTS_TOUCH } from '@web/ui-kit/util/globals'

export interface DisplayPlatform {
  android: boolean
  ios: boolean
  cordova: boolean
  electron: boolean
  chrome: boolean
  edge: boolean
  firefox: boolean
  opera: boolean
  win: boolean
  mac: boolean
  linux: boolean
  touch: boolean
  tablet: boolean
  ssr: boolean
}

export function useDisplay () {
  const windowWidth = ref(0)
  const windowHeight = ref(0)
  const screenOrientation = ref<'portrait' | 'landscape'>('portrait')

  function getClientWidth (): number {
    if (IN_BROWSER) {
      windowWidth.value = window.innerWidth
      return window.innerWidth
    }

    return 0
  }

  function getClientHeight (): number {
    if (IN_BROWSER) {
      windowHeight.value = window.innerHeight
      return window.innerHeight
    }

    return 0
  }

  function getPlatform (): DisplayPlatform {
    const userAgent = IN_BROWSER
      ? window.navigator.userAgent
      : 'ssr'

    function match (regexp: RegExp) {
      return Boolean(userAgent.match(regexp))
    }

    const android = match(/android/i)
    const ios = match(/iphone|ipad|ipod/i)
    const cordova = match(/cordova/i)
    const electron = match(/electron/i)
    const chrome = match(/chrome/i)
    const edge = match(/edge/i)
    const firefox = match(/firefox/i)
    const opera = match(/opera/i)
    const win = match(/win/i)
    const mac = match(/mac/i)
    const linux = match(/linux/i)
    const tablet = match(/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i)

    return {
      android,
      ios,
      cordova,
      electron,
      chrome,
      edge,
      firefox,
      opera,
      win,
      mac,
      linux,
      tablet,
      touch: SUPPORTS_TOUCH,
      ssr: userAgent === 'ssr'
    }
  }

  function getScreenOrientation (): 'portrait' | 'landscape' {
    let _orientation: 'portrait' | 'landscape' = 'portrait'
    if (screen?.orientation?.type) {
      if (screen?.orientation?.type.indexOf('portrait') > -1) {
        _orientation = 'portrait'
      }
      if (screen?.orientation?.type.indexOf('landscape') > -1) {
        _orientation = 'landscape'
      }
    }

    screenOrientation.value = _orientation
    return _orientation
  }

  function onResizeWindow () {
    getClientWidth()
    getClientHeight()
    getScreenOrientation()
  }

  if (IN_BROWSER) {
    onResizeWindow()
    window.addEventListener('resize', onResizeWindow)
  }

  onBeforeUnmount(() => {
    if (IN_BROWSER) {
      window.removeEventListener('resize', onResizeWindow)
    }
  })

  return {
    windowWidth,
    windowHeight,
    screenOrientation,
    getClientWidth,
    getClientHeight,
    getPlatform,
    getScreenOrientation
  }
}
