
import { computed, defineComponent } from 'vue'
import HOMEWORK_STATUS from '@web/consts/HomeworkStatus'

export default defineComponent({
  name: 'DayHomework',
  props: {
    homework: {
      type: Object,
      default: () => ({})
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const isDone = computed(() => props.homework.status.status === HOMEWORK_STATUS.DONE)

    const link = computed(() => {
      if (props.isDisabled) {
        return ''
      }
      return {
        name: 'homework',
        params: {
          homeworkId: props.homework.status.id,
          streamId: props.homework.status.streamId
        }
      }
    })

    return {
      isDone,
      link
    }
  }
})
