import videojs from 'video.js'

const Component = videojs.getComponent('Component')

export default class PlayerControls extends Component {
  constructor (player, options) {
    super(player, options)

    this.render(options.handlerAirPlay, options.openMenu)
  }

  createEl () {
    return videojs.dom.createEl('div', {
      className: 'vjs-controls'
    })
  }

  seek (secs) {
    const _this = this as videojs.Component
    let time = _this.player().currentTime() + secs

    if (time < 0) {
      time = 0
    }

    _this.player().currentTime(time)
  }

  hasAirPlayAPISupport () {
    return Boolean(window.WebKitPlaybackTargetAvailabilityEvent)
  }

  render (handlerAirPlay, openMenu) {
    const _this = this as videojs.Component

    const btnAirPlayEl = videojs.dom.createEl('span', { className: 'vjs-btn-airplay -controls' })
    // eslint-disable-next-line no-console
    console.log('controls hasAirPlayAPISupport', this.hasAirPlayAPISupport())
    if (this.hasAirPlayAPISupport()) {
      btnAirPlayEl.classList.add('-supported')
    }
    btnAirPlayEl.addEventListener('click', () => {
      handlerAirPlay()
    })
    _this.el().appendChild(btnAirPlayEl)

    const btnBackwardEl = videojs.dom.createEl('span', { className: 'vjs-btn-backward -controls' })
    btnBackwardEl.addEventListener('click', () => {
      this.seek(-15)
    })
    _this.el().appendChild(btnBackwardEl)

    const btnPlayEl = videojs.dom.createEl('span', { className: 'vjs-btn-play -pause -controls' })
    btnPlayEl.addEventListener('click', () => {
      if (_this.player().paused()) {
        _this.player().play()
      } else {
        _this.player().pause()
      }
    })
    _this.el().appendChild(btnPlayEl)

    _this.player().on('pause', () => {
      btnPlayEl.classList.add('-pause')
    })

    _this.player().on('play', () => {
      btnPlayEl.classList.remove('-pause')
    })

    const btnForwardEl = videojs.dom.createEl('span', { className: 'vjs-btn-forward -controls' })
    btnForwardEl.addEventListener('click', () => {
      this.seek(15)
    })
    _this.el().appendChild(btnForwardEl)

    const btnSettingsEl = videojs.dom.createEl('span', { className: 'vjs-btn-settings -controls' })
    btnSettingsEl.addEventListener('click', () => {
      openMenu()
    })
    _this.el().appendChild(btnSettingsEl)
  }
}
