import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02ad7888"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "acDay_wrapper" }
const _hoisted_2 = {
  class: "acDay_course course",
  ref: "refCourse"
}
const _hoisted_3 = { class: "course_title" }
const _hoisted_4 = { class: "course_subtitle" }
const _hoisted_5 = { class: "acDay_content" }
const _hoisted_6 = { class: "acDay_row" }
const _hoisted_7 = {
  class: "acDay_column player",
  ref: "refPlayerWrapper"
}
const _hoisted_8 = { class: "acDay_row player_wrapper" }
const _hoisted_9 = {
  key: 0,
  class: "acDay_row"
}
const _hoisted_10 = {
  key: 1,
  class: "acDay_row materials"
}
const _hoisted_11 = {
  class: "acDay_column lessons",
  ref: "refLessonsWrapper"
}
const _hoisted_12 = {
  key: 1,
  class: "acDay_preloader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_banners_top = _resolveComponent("as-banners-top")!
  const _component_as_banner_instead_video = _resolveComponent("as-banner-instead-video")!
  const _component_pretty_player = _resolveComponent("pretty-player")!
  const _component_day_player = _resolveComponent("day-player")!
  const _component_as_banner = _resolveComponent("as-banner")!
  const _component_material = _resolveComponent("material")!
  const _component_materials = _resolveComponent("materials")!
  const _component_day_filter_modal = _resolveComponent("day-filter-modal")!
  const _component_day_lesson = _resolveComponent("day-lesson")!
  const _component_day_homework = _resolveComponent("day-homework")!
  const _component_day_lessons = _resolveComponent("day-lessons")!
  const _component_as_preloader = _resolveComponent("as-preloader")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["acDay", `pDay-${_ctx.dayId}`])
      }, [
        (_ctx.isDesktop)
          ? (_openBlock(), _createBlock(_component_as_banners_top, {
              key: 0,
              banners: _ctx.bannersTop,
              position: _ctx.bannersPosition,
              placement: _ctx.bannersPlacements.top,
              platform: _ctx.bannersPlatforms.desktop
            }, null, 8, ["banners", "position", "placement", "platform"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.course.caption), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.course.coachName), 1)
          ], 512),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (!_ctx.isDesktop && _ctx.showVideo)
                    ? (_openBlock(), _createBlock(_component_pretty_player, {
                        key: 0,
                        "lesson-index": _ctx.currentLessonIndex,
                        lessons: _ctx.filteredLessons,
                        onUpdate: _ctx.selectLesson,
                        onPlay: _ctx.videoPlay,
                        onTimeupdate: _ctx.receiveVideoTime,
                        onEnded: _ctx.videoEnded,
                        onFirstPlay: _ctx.firstPlay,
                        onProgress: _ctx.updateVideoProgress,
                        onClose: _ctx.closeVideo,
                        onReady: _ctx.onPlayerReady,
                        "full-page": _ctx.playerIsFullPage,
                        "auto-play": _ctx.autoPlayNextVideoMobile,
                        tablet: _ctx.platform.tablet
                      }, _createSlots({ _: 2 }, [
                        (_ctx.showBannerInsteadVideo && _ctx.bannerMobileInsteadVideo)
                          ? {
                              name: "banner",
                              fn: _withCtx(() => [
                                _createVNode(_component_as_banner_instead_video, {
                                  banner: _ctx.bannerMobileInsteadVideo,
                                  position: _ctx.bannersPosition,
                                  placement: _ctx.bannersPlacements.insteadVideo,
                                  platform: _ctx.bannersPlatforms.mobile,
                                  onEnded: _ctx.onBannerInsteadVideoEnded,
                                  class: "banner-instead-video"
                                }, null, 8, ["banner", "position", "placement", "platform", "onEnded"])
                              ]),
                              key: "0"
                            }
                          : undefined
                      ]), 1032, ["lesson-index", "lessons", "onUpdate", "onPlay", "onTimeupdate", "onEnded", "onFirstPlay", "onProgress", "onClose", "onReady", "full-page", "auto-play", "tablet"]))
                    : _createCommentVNode("", true),
                  (_ctx.isDesktop)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (_ctx.playerReady && _ctx.bannerInsteadVideo)
                          ? (_openBlock(), _createBlock(_component_as_banner_instead_video, {
                              key: 0,
                              banner: _ctx.bannerInsteadVideo,
                              position: _ctx.bannersPosition,
                              placement: _ctx.bannersPlacements.insteadVideo,
                              platform: _ctx.bannersPlatforms.desktop,
                              onEnded: _ctx.onBannerInsteadVideoEnded,
                              class: "banner-instead-video"
                            }, null, 8, ["banner", "position", "placement", "platform", "onEnded"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_day_player, {
                          lessonIndex: _ctx.currentLessonIndex,
                          lessons: _ctx.filteredLessons,
                          onUpdate: _ctx.selectLesson,
                          onPlay: _ctx.videoPlay,
                          onTimeupdate: _ctx.receiveVideoTime,
                          onEnded: _ctx.videoEnded,
                          onFirstPlay: _ctx.firstPlay,
                          onProgress: _ctx.updateVideoProgress,
                          onReady: _ctx.onPlayerReady,
                          play: _ctx.playPlayer,
                          "auto-play-next-video": _ctx.autoPlayNextVideoMobile
                        }, null, 8, ["lessonIndex", "lessons", "onUpdate", "onPlay", "onTimeupdate", "onEnded", "onFirstPlay", "onProgress", "onReady", "play", "auto-play-next-video"])
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.bannerMobileAfterVideo && !_ctx.isDesktop)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_as_banner, {
                        banner: _ctx.bannerMobileAfterVideo,
                        "in-day": "",
                        platform: _ctx.bannersPlatforms.mobile,
                        position: _ctx.bannersPosition,
                        placement: _ctx.bannersPlacements.afterVideo
                      }, null, 8, ["banner", "platform", "position", "placement"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isShowMaterials)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_materials, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.course.documents, (doc) => {
                            return (_openBlock(), _createBlock(_component_material, {
                              key: doc.id,
                              doc: doc,
                              class: "material"
                            }, null, 8, ["doc"]))
                          }), 128)),
                          _createVNode(_component_material, {
                            doc: {
                    url: _ctx.course.instructionUrl,
                    title: _ctx.$t('userInstruction')
                  },
                            "is-modal": true
                          }, null, 8, ["doc"])
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ], 512),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_day_lessons, {
                  progress: _ctx.progress,
                  "video-count": _ctx.videoCount,
                  ref: "refDayLessons"
                }, _createSlots({
                  default: _withCtx(() => [
                    (_ctx.bannerBeforeLessons && _ctx.isDesktop)
                      ? (_openBlock(), _createBlock(_component_as_banner, {
                          key: 0,
                          banner: _ctx.bannerBeforeLessons,
                          "in-day": "",
                          platform: _ctx.bannersPlatforms.desktop,
                          position: _ctx.bannersPosition,
                          placement: _ctx.bannersPlacements.beforeLessons,
                          class: "banner-before-lessons"
                        }, null, 8, ["banner", "platform", "position", "placement"]))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredLessons, (lesson, i) => {
                      return (_openBlock(), _createBlock(_component_day_lesson, {
                        key: `day-lesson-${i}`,
                        lesson: lesson,
                        class: _normalizeClass([_ctx.currentLessonIndex === i ? '-current' : '', 'lesson']),
                        onClick: ($event: any) => (_ctx.selectLesson(i)),
                        "data-analytics": "",
                        "data-analytics-element-type": "playlist",
                        "data-analytics-lesson-id": lesson.id,
                        "data-analytics-video-type": lesson.type,
                        "data-analytics-video-orientation": lesson.orientation,
                        "data-analytics-lesson-title": lesson.title
                      }, null, 8, ["lesson", "class", "onClick", "data-analytics-lesson-id", "data-analytics-video-type", "data-analytics-video-orientation", "data-analytics-lesson-title"]))
                    }), 128)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.homeworks, (homework, id) => {
                      return (_openBlock(), _createBlock(_component_day_homework, {
                        key: `day-homework-${id}`,
                        homework: homework,
                        "is-disabled": _ctx.isHomeworkDisabled,
                        class: "homework"
                      }, null, 8, ["homework", "is-disabled"]))
                    }), 128))
                  ]),
                  _: 2
                }, [
                  (_ctx.isShowBtnCourseOptions)
                    ? {
                        name: "filterModal",
                        fn: _withCtx(() => [
                          _createVNode(_component_day_filter_modal, {
                            options: _ctx.options,
                            filters: _ctx.filters,
                            streamId: _ctx.streamId,
                            courseId: _ctx.courseId,
                            dayId: _ctx.dayId
                          }, null, 8, ["options", "filters", "streamId", "courseId", "dayId"])
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["progress", "video-count"])
              ], 512)
            ])
          ])
        ]),
        _createVNode(_component_as_banners_top, {
          banners: _ctx.isDesktop ? _ctx.bannersBottom : _ctx.bannersMobileBottom,
          position: _ctx.bannersPosition,
          placement: _ctx.bannersPlacements.bottom,
          platform: _ctx.isDesktop ? _ctx.bannersPlatforms.desktop : _ctx.bannersPlatforms.mobile
        }, null, 8, ["banners", "position", "placement", "platform"])
      ], 2))
    : (_openBlock(), _createElementBlock("div", _hoisted_12, [
        _createVNode(_component_as_preloader)
      ]))
}