import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "day-filterModal_content" }
const _hoisted_2 = ["selected", "value"]
const _hoisted_3 = ["selected"]
const _hoisted_4 = ["selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ac_checkbox = _resolveComponent("ac-checkbox")!
  const _component_ac_select = _resolveComponent("ac-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (option.type === 'boolean')
          ? (_openBlock(), _createBlock(_component_ac_checkbox, {
              "model-value": _ctx.isChecked(option.value),
              "onUpdate:modelValue": ($event: any) => (_ctx.checked(option)),
              key: `checkbox-${i}`,
              label: option.userName,
              class: "day-filterModal_checkbox"
            }, null, 8, ["model-value", "onUpdate:modelValue", "label"]))
          : _createCommentVNode("", true),
        (option.type === 'enum')
          ? (_openBlock(), _createBlock(_component_ac_select, {
              "model-value": option.value.id,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.select($event))),
              key: `select${i}`,
              label: option.userName,
              class: "day-filterModal_select"
            }, {
              options: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(option.trainingLessonOptionValues, (value) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: `option${value.id}`,
                    selected: option.value.id === value.id,
                    value: JSON.stringify(value)
                  }, _toDisplayString(value.value), 9, _hoisted_2))
                }), 128))
              ]),
              _: 2
            }, 1032, ["model-value", "label"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 256)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter, key) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (key === 'orientation')
          ? (_openBlock(), _createBlock(_component_ac_select, {
              "model-value": filter,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectOrientation($event))),
              key: `checkedFilter${key}`,
              "data-test": `checkedFilter-${key}`,
              label: _ctx.$t(`lessonTypeEnable.${key}`),
              class: "day-filterModal_select"
            }, {
              options: _withCtx(() => [
                _createElementVNode("option", {
                  selected: filter === 'horizontal',
                  value: "horizontal"
                }, _toDisplayString(_ctx.$t('lessonTypeEnable.horizontal')), 9, _hoisted_3),
                _createElementVNode("option", {
                  selected: filter === 'vertical',
                  value: "vertical"
                }, _toDisplayString(_ctx.$t('lessonTypeEnable.vertical')), 9, _hoisted_4)
              ]),
              _: 2
            }, 1032, ["model-value", "data-test", "label"]))
          : (_openBlock(), _createBlock(_component_ac_checkbox, {
              "model-value": filter,
              "onUpdate:modelValue": ($event: any) => (_ctx.checkedFilter({ value: filter, key })),
              key: `checkboxFilter-${key}`,
              "data-test": `checkedFilter-${key}`,
              label: _ctx.$t(`lessonTypeEnable.${key}`),
              class: "day-filterModal_checkbox"
            }, null, 8, ["model-value", "onUpdate:modelValue", "data-test", "label"]))
      ], 64))
    }), 256))
  ]))
}