import * as AnalyticEvents from '@web/services/Analytics/events'
import { useStore } from '@web/store'

export default function useSettingModal ({
  streamId
}: {
  streamId: number;
}) {
  const store = useStore()

  const isChecked = (value: TrainingOptionValue) => String(value.systemName) === 'true'

  function updateFilter ({ key, value }: { key: number | string; value: number | string | boolean }) {
    store.dispatch('stream/updateFilter', {
      streamId,
      key,
      value
    })
    AnalyticEvents.filterChange({
      filterName: String(key),
      filterValue: String(value)
    })
  }

  function select ($event: string) {
    const value: {
      optionId: number;
      id: number;
    } = JSON.parse($event)
    updateFilter({
      key: value.optionId,
      value: value.id
    })
  }

  function checked (value: CourseOptions & { value: TrainingLessonOptionValue}) {
    const trainingLessonOptionValue = value.trainingLessonOptionValues.find(option => option.id !== value.value.id)
    updateFilter({
      key: value.id,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      value: trainingLessonOptionValue!.id
    })
  }

  function checkedFilter ({ value, key }: { value: boolean; key: number }) {
    updateFilter({
      key,
      value: !value
    })
  }

  function selectOrientation (value: string) {
    const key = 'orientation'
    store.dispatch('stream/updateFilter', {
      streamId,
      value,
      key
    })

    AnalyticEvents.filterChange({
      filterName: key,
      filterValue: String(value)
    })
  }

  return {
    isChecked,
    select,
    checked,
    checkedFilter,
    selectOrientation
  }
}
