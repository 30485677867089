import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41208c00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "day-player" }
const _hoisted_2 = { class: "day-player_wrap" }
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        id: _ctx.playerId,
        class: "day-player_video"
      }, null, 8, _hoisted_3)
    ])
  ]))
}