import { useStore } from '@web/store'
import STAGE_STATUS from '@web/consts/StageStatus'
import { isDefined } from 'ts-is-present'
import { computed, Ref } from 'vue'
import { Router } from 'vue-router'
import { getDays as getDaysStream } from '@web/common/Stream'
import { Stream } from '@web/store/types/modules/stream'

function getDays ({ course, streamId }: { course: Course; streamId: number }): FlatArray<(Day[])[], 1>[] {
  const store = useStore()

  const stream = store.getters['stream/getStreamById'](streamId) as Stream
  const stageIds: number[] = store.getters['course/getCourseStageIds'](course.id)

  return stageIds
    .map(stageId => store.getters['stream/getStageStatus']({ streamId, stageId }))
    .filter((stageStatus): stageStatus is StageStatus => stageStatus?.status === STAGE_STATUS.OPEN)
    .map((stageStatus: StageStatus) => {
      const stage = store.getters['stage/getStageById'](stageStatus.id)
      if (stage) {
        return {
          ...stage,
          stageStatus
        }
      }
      return undefined
    })
    .filter(isDefined)
    .sort((s1, s2) => s1.sort - s2.sort)
    .map<Day[]>(stage => {
      return getDaysStream(stage, stage.stageStatus, stream, [])
        .filter(isDefined)
        .filter(d => d.status && !d.status.isLocked)
        .filter(d => d.isOpen)
        .map(d => d.day)
        .filter((day): day is Day => day !== undefined && !day.dayOfRest)
        .sort((d1, d2) => d1.dayNumber - d2.dayNumber)

      // Пока оставить
      // return stage.dayIds
      //   .map(dayId => store.getters['stream/getDayStatus']({ streamId, dayId }))
      //   .filter(isDefined)
      //   .filter(dayStatus => dayStatus && !dayStatus.isLocked)
      //   // не использую getDayById т.к он подтягивает так же уроки, а это в данном месте не нужно
      //   .map<Day | undefined>(({ stageDayId }) => store.state.day.all[stageDayId])
      //   .filter((day): day is Day => day !== undefined && !day.dayOfRest)
      //   .sort((d1, d2) => d1.dayNumber - d2.dayNumber)
    })
    .flat()
}

type params = {
  course: Course;
  streamId: number;
  day: Readonly<Ref<Readonly<Day>>>;
  router: Router;
}
export default function useDayNav ({ course, streamId, day, router }: params) {
  const days = computed(() => getDays({ course, streamId }))
  const showPrevDayButton = computed<boolean>(() => days.value.length > 0 && day.value?.id !== days.value[0]?.id)
  const showNextDayButton = computed<boolean>(() => days.value.length > 0 && day.value?.id !== days.value[days.value.length - 1]?.id)

  function goToDay (type) {
    let dayIndex = days.value.findIndex(({ id }: { id: number }) => id === day.value?.id)

    if (dayIndex !== -1) {
      if (type === 'prev') dayIndex--
      if (type === 'next') dayIndex++

      const newDay: Day | undefined = days.value[dayIndex]
      if (!newDay) return

      router.push({
        name: 'day',
        params: {
          courseId: course.id.toString(),
          dayNumber: newDay.dayNumber.toString(),
          streamId: streamId.toString(),
          stageId: newDay.stageId.toString(),
          dayId: newDay.id.toString()
        }
      })
    }
  }

  return {
    showNextDayButton,
    showPrevDayButton,
    goToDay,
    days
  }
}
