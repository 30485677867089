import videojs from 'video.js'
import { watch } from 'vue'
import i18n from '@web/plugins/i18n'

export default class PlayerHeader extends videojs.getComponent('Component') {
  constructor (player, options) {
    super(player, options)

    const {
      video,
      handlerAirPlay,
      count,
      close,
      openMenu
    } = options
    this.render(video, handlerAirPlay, count, close, openMenu)
  }

  createEl () {
    return videojs.dom.createEl('div', {
      className: 'vjs-header'
    })
  }

  hasAirPlayAPISupport () {
    return Boolean(window.WebKitPlaybackTargetAvailabilityEvent)
  }

  render (video, handlerAirPlay, count, close, openMenu) {
    const _this = this as videojs.Component
    _this.el().innerHTML = ''

    const titleOnOfEl = document.createElement('span')
    titleOnOfEl.classList.add('vjs-on-of')
    titleOnOfEl.innerText = i18n.global.t('videoFrom', { n: video.value.index, max: count })
    _this.el().appendChild(titleOnOfEl)

    const titleEl = document.createElement('span')
    titleEl.classList.add('vjs-title')
    titleEl.innerText = video.value.title
    _this.el().appendChild(titleEl)

    watch(video, () => {
      if (!_this.el()) {
        return
      }

      const titleOnOfEl: HTMLElement | null = _this.el().querySelector('.vjs-on-of')
      if (titleOnOfEl) {
        titleOnOfEl.innerText = i18n.global.t('videoFrom', { n: video.value.index, max: count })
      }

      const titleEl: HTMLElement | null = _this.el().querySelector('.vjs-title')
      if (titleEl) {
        titleEl.innerText = `${video.value.title} ${video.value.subtitle ? `(${video.value.subtitle})` : ''}`
      }
    })

    const btnCloseEl = document.createElement('span')
    btnCloseEl.classList.add('vjs-btn-close')
    btnCloseEl.addEventListener('click', close)
    _this.el().appendChild(btnCloseEl)

    const btnSettingsEl = videojs.dom.createEl('span')
    btnSettingsEl.classList.add('vjs-btn-settings')
    btnSettingsEl.classList.add('-header')
    btnSettingsEl.addEventListener('click', openMenu)
    _this.el().appendChild(btnSettingsEl)

    const btnAirPlayEl = videojs.dom.createEl('span', { className: 'vjs-btn-airplay -header' })
    // eslint-disable-next-line no-console
    console.log('header hasAirPlayAPISupport', this.hasAirPlayAPISupport())
    if (this.hasAirPlayAPISupport()) {
      btnAirPlayEl.classList.add('-supported')
    }
    btnAirPlayEl.addEventListener('click', () => {
      handlerAirPlay()
    })
    _this.el().appendChild(btnAirPlayEl)
  }
}
