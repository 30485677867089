
import { defineComponent, computed } from 'vue'
import useLesson from '@web/composition/useLesson'

export default defineComponent({
  name: 'DayLesson',
  props: {
    lesson: {
      type: Object,
      default: () => ({})
    }
  },
  setup () {
    const {
      time
    } = useLesson()

    const status = computed(() => {
      // TODO Пока нет возможности узнать просмотрен урок или нет
      return ''
    })

    return {
      time,
      status
    }
  }
})
