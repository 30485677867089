import videojs from 'video.js'

// TODO Разобраться почему не подтягивается тип videojs.Component
const Component = videojs.getComponent('Component')

export default class PlayerRepeat extends Component {
  constructor (player, options) {
    super(player, options)

    this.render()
  }

  createEl () {
    return videojs.dom.createEl('div', {
      className: 'vjs-btn-repeat'
    })
  }

  render () {
    const _this = this as videojs.Component
    _this.el().addEventListener('click', () => {
      _this.player().currentTime(0)
      _this.player().play()
    })
  }
}
