
import { defineComponent } from 'vue'
import AcProgress from '@ui-kit/components/AcProgress.vue'

export default defineComponent({
  name: 'DayLessons',
  components: {
    AcProgress
  },
  props: {
    progress: {
      type: Number,
      required: true
    },
    videoCount: {
      type: Number,
      default: 0
    }
  }
})
