import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "prettyPlayer_banner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bottom_menu = _resolveComponent("bottom-menu")!

  return (_openBlock(), _createBlock(_Teleport, {
    to: "#modals",
    disabled: !_ctx.fullPage
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["prettyPlayer", _ctx.classes]),
      ref: "refPrettyPlayer"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "banner")
      ]),
      _createElementVNode("video", {
        class: _normalizeClass([_ctx.fullPage ? '-fullPage' : '', 'video-js']),
        ref: "playerNode",
        preload: "auto",
        "x-webkit-airplay": "allow",
        playsinline: ""
      }, null, 2),
      (_ctx.isOpenMenu)
        ? (_openBlock(), _createBlock(_component_bottom_menu, {
            key: 0,
            items: _ctx.menuItems,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenMenu = false)),
            onUpdate: _ctx.onUpdateMenuItem,
            class: _normalizeClass([_ctx.fullPage ? '-fullPage' : ''])
          }, null, 8, ["items", "onUpdate", "class"]))
        : _createCommentVNode("", true)
    ], 2)
  ], 8, ["disabled"]))
}