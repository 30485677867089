
import { computed, defineComponent, onMounted, watch } from 'vue'

import { DayLesson } from '@web/store/types/modules/day'
import { useStore } from '@web/store'
import usePlayer from '@web/composition/usePlayer'

export default defineComponent({
  name: 'DayPlayer',
  props: {
    playerId: {
      type: String,
      default: 'acPlayer'
    },
    lessons: {
      type: Array as () => DayLesson[],
      required: true
    },
    lessonIndex: {
      type: Number,
      required: true
    },
    play: {
      type: Boolean,
      default: false
    },
    autoPlayNextVideo: {
      type: Boolean,
      default: true
    }
  },
  emits: ['play', 'firstPlay', 'timeupdate', 'progress', 'updateSpeed', 'ended', 'update', 'ready'],
  setup (props, ctx) {
    const store = useStore()
    const player = usePlayer({
      rawVideos: computed(() => props.lessons),
      targetId: props.playerId,
      wid: store.getters['user/user'].id,
      lessonIndex: computed(() => props.lessonIndex),
      autoPlayNextVideo: computed(() => props.autoPlayNextVideo),
      callbacks: {
        play: () => {
          ctx.emit('play')
        },
        firstPlay: () => {
          ctx.emit('firstPlay')
        },
        timeupdate: time => {
          ctx.emit('timeupdate', time)
        },
        progress: progress => {
          ctx.emit('progress', progress)
        },
        updateSpeed: speed => {
          ctx.emit('updateSpeed', speed)
        },
        ended: () => {
          ctx.emit('ended')
        },
        playlistItemChanged: (index) => {
          ctx.emit('update', index)
        }
      }
    })

    watch(() => props.lessonIndex, index => {
      if (player.playerReady) {
        player.change(index, props.autoPlayNextVideo)
      } else if (player.checkPlayerNode()) {
        player.init()
      }
    })

    watch(
      () => props.lessons,
      (lessons: readonly DayLesson[], lessonsOld: readonly DayLesson[] | undefined) => {
        if (!lessonsOld) {
          return
        }
        if (lessons.length !== lessonsOld.length) {
          player.init()
          ctx.emit('update', 0)
          return
        }
        const hasDiff = lessons.find((v, i) => v.video.id !== lessonsOld[i].video.id)
        if (hasDiff) {
          player.init()
          ctx.emit('update', 0)
        }
      }
    )

    watch(() => props.play, nPlay => {
      if (player.playerReady && nPlay) {
        player.playPlayer()
        player.mutePlayer(false)
      } else if (player.checkPlayerNode()) {
        player.init()
      }
    })

    watch(player.playerReady, (nVal, oVal) => {
      if (nVal && nVal !== oVal) {
        ctx.emit('ready')
      }
    })

    onMounted(player.init)
  }
})
