import videojs from 'video.js'
import { watch } from 'vue'
import i18n from '@web/plugins/i18n'

const Component = videojs.getComponent('Component')

export default class PlayerFooter extends Component {
  constructor (player, options) {
    super(player, options)

    const {
      next,
      prev,
      delayBetweenVideo,
      offer,
      videos,
      currentIndex
    } = options
    this.render(next, prev, delayBetweenVideo, offer, videos, currentIndex)
  }

  createEl () {
    return videojs.dom.createEl('div', {
      className: 'vjs-footer'
    })
  }

  seek (secs) {
    const _this = this as videojs.Component
    let time = _this.player().currentTime() + secs

    if (time < 0) {
      time = 0
    }

    _this.player().currentTime(time)
  }

  render (next, prev, delayBetweenVideo, offer, videos, currentIndex) {
    const _this = this as videojs.Component
    const rowEl = videojs.dom.createEl('div', { className: 'vjs-footer-row -btns' })

    const btnPrevEl = videojs.dom.createEl('span', { className: 'vjs-btn-prev' })
    btnPrevEl.addEventListener('click', prev)
    btnPrevEl.innerHTML = `<span class="-text">${i18n.global.t('player.prevVideo')}</span>` +
    '<span class="-icon">' +
    '  <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '    <path d="M7.69719 0.967548C8.18779 1.44715 8.22629 2.11485 7.69719 2.70115L3.57549 6.99995L7.69719 11.2987C8.22629 11.885 8.18779 12.5538 7.69719 13.0301C7.20769 13.5097 6.38049 13.4789 5.92069 13.0301C5.46089 12.5835 0.968494 7.86565 0.968494 7.86565C0.852055 7.7533 0.759446 7.61865 0.696193 7.46972C0.632941 7.32079 0.600342 7.16065 0.600342 6.99885C0.600342 6.83705 0.632941 6.6769 0.696193 6.52798C0.759446 6.37905 0.852055 6.2444 0.968494 6.13205C0.968494 6.13205 5.46089 1.41635 5.92069 0.967548C6.38049 0.517648 7.20769 0.487948 7.69719 0.967548Z" fill="white"/>' +
    '  </svg>' +
    '</span>'
    rowEl.appendChild(btnPrevEl)

    const rowControls = videojs.dom.createEl('div', { className: 'vjs-footer-controls -footer' })
    const btnBackwardEl = videojs.dom.createEl('span', { className: 'vjs-btn-backward -footer' })
    btnBackwardEl.addEventListener('click', () => {
      this.seek(-15)
    })
    rowControls.appendChild(btnBackwardEl)

    const btnPlayEl = videojs.dom.createEl('span', { className: 'vjs-btn-play -pause -footer' })
    btnPlayEl.addEventListener('click', () => {
      if (_this.player().paused()) {
        _this.player().play()
      } else {
        _this.player().pause()
      }
    })
    rowControls.appendChild(btnPlayEl)

    _this.player().on('pause', () => {
      btnPlayEl.classList.add('-pause')
    })

    _this.player().on('play', () => {
      btnPlayEl.classList.remove('-pause')
    })

    const btnForwardEl = videojs.dom.createEl('span', { className: 'vjs-btn-forward -footer' })
    btnForwardEl.addEventListener('click', () => {
      this.seek(15)
    })
    rowControls.appendChild(btnForwardEl)

    rowEl.appendChild(rowControls)

    const btnNextEl = videojs.dom.createEl('span', { className: 'vjs-btn-next' })
    btnNextEl.addEventListener('click', next)
    btnNextEl.innerHTML = `<span>${i18n.global.t('player.nextVideo')}</span>`
    rowEl.appendChild(btnNextEl)

    const lineEl = videojs.dom.createEl('div', { className: 'white-line' }) as HTMLElement
    btnNextEl.appendChild(lineEl)

    _this.player().on('ended', () => {
      const KEY_STORE_AUTONEXT = 'pp_auto_next'
      const autoNext = localStorage.getItem(KEY_STORE_AUTONEXT) ? localStorage.getItem(KEY_STORE_AUTONEXT) === 'true' : true
      if (autoNext) {
        setTimeout(() => {
          lineEl.style.transition = `width ${delayBetweenVideo}ms ease-out`
          lineEl.style.width = '100%'
        }, 100)
      }
    })

    _this.player().on('play', () => {
      lineEl.style.transition = 'width 0ms linear'
      lineEl.style.width = '0'
    })
    _this.el().appendChild(rowEl)

    const btnOffer = videojs.dom.createEl('div', { className: 'vjs-offer-button' })
    btnOffer.addEventListener('click', offer)
    btnOffer.innerHTML = 'Купить полный доступ'
    _this.el().appendChild(btnOffer)

    watch(currentIndex, (newCurrentIndex: number) => {
      // Hide prev button
      if (newCurrentIndex === 0) {
        (btnPrevEl as HTMLSpanElement).style.visibility = 'hidden'
      } else {
        (btnPrevEl as HTMLSpanElement).style.visibility = 'visible'
      }

      // Hide next button
      if (videos.length - 1 > newCurrentIndex) {
        (btnNextEl as HTMLSpanElement).style.visibility = 'visible'
      } else {
        (btnNextEl as HTMLSpanElement).style.visibility = 'hidden'
      }
    }, {
      immediate: true
    })
  }
}
