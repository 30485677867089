
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AcCheckbox',
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  setup (props, ctx) {
    function toggleCheckbox () {
      ctx.emit('update:modelValue', !props.modelValue)
    }

    return {
      toggleCheckbox
    }
  }
})
