
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import useDayNav from '@web/composition/useDayNav'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    progress: {
      type: Number,
      default: 0
    },
    course: {
      type: Object as () => Course,
      required: true
    },
    streamId: {
      type: Number,
      required: true
    },
    day: {
      type: Object as () => Day,
      required: true
    }
  },
  setup (props) {
    const router = useRouter()
    const containerEl = ref<HTMLElement | null>(null)
    const currentDay = computed(() => props.day)
    const currentDayId = computed(() => currentDay.value.id)

    const {
      days,
      ...data
    } = useDayNav({
      course: props.course,
      streamId: props.streamId,
      day: currentDay,
      router
    })

    const showTabs = computed(() => days.value.length > 0)

    function scrollToElement (day) {
      if (day) {
        const tabEl = containerEl.value?.children[0]
        if (tabEl) {
          const dayIndex: number = days.value.findIndex(el => {
            return el.id === day.id
          })
          const offsetLeft = tabEl.clientWidth * dayIndex
          containerEl.value?.scroll({
            left: offsetLeft,
            behavior: 'smooth'
          })
        }
      }
    }

    onMounted(() => {
      scrollToElement(currentDay.value)
    })

    watch(currentDay, (newVal, oldVal) => {
      if (newVal && newVal.id !== oldVal.id) {
        scrollToElement(newVal)
      }
    })

    return {
      ...data,
      currentDay,
      currentDayId,
      showTabs,
      days,
      containerEl
    }
  }
})
