import { isDefined } from 'ts-is-present'
import Logger from '@web/common/Logger'

/**
 * Добавление в courseOptions выбранного юзером значения в поле value
 *
 * @param {array} courseOptions
 * @param {object} userOptions
 * @returns {array}
 */
export default ({ courseOptions = [], filters = {} }: { courseOptions: CourseOptions[]; filters: LessonFilters }):
  (CourseOptions & { value: TrainingLessonOptionValue})[] => {
  return courseOptions
    .map((courseOption) => {
      const options = courseOption.trainingLessonOptionValues
      let value = options.find(value => value.id === filters[courseOption.id])
      if (!value) {
        value = options.find(value => value.default)
      }
      if (!value) {
        value = options[0]
      }

      if (value) {
        return {
          ...courseOption,
          value
        }
      } else {
        Logger.warn('Фильтр заполнен не правильно')
        return undefined
      }
    })
    .filter(isDefined)
}
