
import { defineComponent, ref, computed } from 'vue'

export default defineComponent({
  props: {
    items: {
      type: Array as () => Array<MenuItemCheckbox | MenuItemSelect>,
      required: true
    }
  },
  emits: ['close', 'update'],
  setup (props, ctx) {
    const menuType = ref('')
    const title = computed(() => props.items.find(({ name }) => name === menuType.value)?.label || '')
    function onClose () {
      menuType.value = ''
      ctx.emit('close')
    }

    function onBack () {
      menuType.value = ''
    }

    function onClickItem (item: MenuItemSelect | MenuItemCheckbox, name?: string) {
      const _name = name || item.name
      if (item.type === 'select') {
        menuType.value = item.name
      } else {
        const value = item.value || item.selected
        ctx.emit('update', { name: _name, value })
      }
    }
    return {
      menuType,
      title,
      onClose,
      onBack,
      onClickItem
    }
  }
})
