
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'

import { useStore } from '@web/store'
import { useRoute, useRouter } from 'vue-router'
import useDay from '@web/composition/useDay'
import { useMeta } from '@web/common/vueMeta'
import { useDisplay } from '@ui-kit/composables'
import i18n from '@web/plugins/i18n'
import Swal from 'sweetalert2'
import sentry from '@web/common/sentry'
import Logger from '@web/common/Logger'
import { performanceEnd } from '@web/common/Utils'
import Analytics from '@web/services/Analytics/Analytics'
import FirebasePerformance from '@web/services/firebasePerformance'
import STREAM_STATUS from '@web/consts/StreamStatus'
import DAY_STATUS from '@web/consts/DayStatus'

import DayPlayer from './Player.vue'
import DayLessons from './Lessons.vue'
import DayLesson from './Lesson.vue'
import DayHomework from './Homework.vue'
import DayFilterModal from './FilterModal/index.vue'
import Materials from '../Materials.vue'
import Material from '../Material.vue'
import AsPreloader from '@web/components/AsPreloader.vue'
import PrettyPlayer from '@web/components.v2/PrettyPlayer/PrettyPlayer.vue'
import AsBannersTop from '@web/components/AsBannersTop.vue'
import AsBannerInsteadVideo from '@web/components/AsBannerInsteadVideo.vue'
import AsBanner from '@web/components/AsBanner.vue'

export default defineComponent({
  name: 'Day',
  components: {
    PrettyPlayer,
    DayFilterModal,
    AsPreloader,
    DayPlayer,
    DayLessons,
    DayLesson,
    DayHomework,
    Materials,
    Material,
    AsBannersTop,
    AsBannerInsteadVideo,
    AsBanner
  },
  emits: ['loaded', 'nextDayLoaded'],
  setup (props, ctx) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const loaded = ref(false)
    const nextDayLoaded = ref(true)
    const refCourse = ref<HTMLDivElement>()
    const refPlayerWrapper = ref<HTMLDivElement>()
    const refLessonsWrapper = ref<HTMLDivElement>()
    const refDayLessons = ref<typeof DayLessons>()
    const playPlayer = ref(false)
    const videoModal = ref(false)
    const playerReady = ref(false)

    const {
      homeworks,
      stream,
      streamId,
      filteredLessons,
      currentLesson,
      dayId,
      stageId,
      setAltDayIdAndAltStageId,
      onOpenDay,
      dayStatus,
      onFinishDay,
      saveLocationToContinue,
      progress,
      checkNecessaryParameters,
      currentLessonIndex,
      course,
      sendStatistic,
      title,
      // BANNERS
      bannersTop,
      bannersBottom,
      bannerBeforeLessons,
      bannerInsteadVideo,
      bannersMobileBottom,
      bannerMobileInsteadVideo,
      bannerMobileAfterVideo,
      bannersPlatforms,
      bannersPlacements,
      bannersPosition,
      ...data
    } = useDay({ route, router })

    useMeta({ title })

    const display = useDisplay()
    const platform = display.getPlatform()

    const isDesktop = !(platform.tablet || platform.android || platform.ios)
    const playerIsFullPage = computed(() => {
      if ((platform.android || platform.ios) && !platform.tablet) {
        return true
      }

      return display.windowWidth.value <= 660
    })
    const courseId = computed(() => stream.value?.courseId)
    const showVideo = computed(() => currentLesson.value && videoModal.value)

    const autoPlayNextVideo = computed(() => {
      if (!bannerInsteadVideo.value) {
        return true
      }
      return playPlayer.value
    })
    const autoPlayNextVideoMobile = computed(() => {
      if (!bannerMobileInsteadVideo.value) {
        return true
      }
      return playPlayer.value
    })

    const isShowMaterials = computed<boolean>(() => {
      if (!course.value) {
        return false
      }
      return (course.value.documents && course.value.documents.length > 0) || !!course.value.instructionUrl
    })

    const isGamificationEnabled = computed(() => Boolean(course.value?.gamificationEnabled) && !stream.value?.courseFullAccess)

    const isHomeworkDisabled = computed(() => {
      if (isGamificationEnabled.value) {
        return false
      }
      if (!dayStatus.value) {
        return true
      }
      return dayStatus.value?.status !== DAY_STATUS.ENDED || progress.value < 100
    })

    async function fetchData () {
      const stream = await store.dispatch('stream/fetchUserCourseStreamCached', { id: streamId.value })
      if (stream.status === STREAM_STATUS.ENDED || stream.status === STREAM_STATUS.STOPPED) {
        router.push({ name: 'courses' })
        return
      }

      await store.dispatch('course/fetchCourse', { id: stream.courseId, requestLessons: true })

      await store.dispatch('stream/fetchStatuses', {
        id: streamId.value,
        expand: 'stages,days,homeworks'
      })

      if (!dayId.value || !stageId.value) {
        await store.dispatch('stage/fetchStagesCached', { courseId: stream.courseId })
        setAltDayIdAndAltStageId()
      }

      await store.dispatch('day/fetchDayCached', { courseId: stream.courseId, stageId: stageId.value, dayId: dayId.value })

      await onOpenDay()
    }

    function showErrorAlert () {
      Swal.fire({
        title: i18n.global.t('error.error'),
        text: i18n.global.t('error.day'),
        icon: 'error'
      })
    }

    function openVideo () {
      videoModal.value = true
      if (!playerIsFullPage.value) {
        const offsetTop = refPlayerWrapper.value?.offsetTop || 0
        window.scroll({
          top: offsetTop,
          behavior: 'smooth'
        })
      } else {
        useMeta({ title: currentLesson.value?.lesson.title || '', showDomain: false })
      }
    }
    function closeVideo () {
      selectLesson(-1)
      videoModal.value = false
      useMeta({ title })
    }

    const showBannerInsteadVideo = ref(true)
    function onBannerInsteadVideoEnded () {
      playPlayer.value = true
      showBannerInsteadVideo.value = false
    }

    function selectLesson (index: number) {
      currentLessonIndex.value = index
      openVideo()
    }

    function getAbsoluteHeight (el) {
      el = (typeof el === 'string') ? document.querySelector(el) : el

      const styles = window.getComputedStyle(el)
      const margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom)

      return Math.ceil(el.offsetHeight + margin)
    }

    function resizeColumns () {
      if (isDesktop) {
        const minMaxHeight = window.innerHeight -
          (document.querySelector('.page-day_header') ? getAbsoluteHeight(document.querySelector('.page-day_header')) : 0) -
          (refCourse.value ? getAbsoluteHeight(refCourse.value) : 0) -
          80 - // Padding top and bottom for AcDay
          25 // Padding bottom for page

        if (refPlayerWrapper.value && refLessonsWrapper.value) {
          const contentHeight = refPlayerWrapper.value.offsetHeight
          refLessonsWrapper.value.style.maxHeight = `${Math.max(minMaxHeight, contentHeight)}px`

          if (refDayLessons.value) {
            refDayLessons.value.$el.style.maxHeight = `${Math.max(minMaxHeight, contentHeight)}px`
          }
        }
      }
    }

    async function init () {
      let dayLoading = performance.now()
      const startLoading = Date.now()
      try {
        loaded.value = false
        ctx.emit('loaded', false)

        await fetchData()
        sendStatistic()
      } catch (e) {
        if (['day', 'day_homework'].includes(String(router.currentRoute.value.name) || '')) {
          sentry.captureException(e)
          Logger.error(e)
          showErrorAlert()
        }
      } finally {
        dayLoading = performanceEnd(dayLoading)
        Logger.info(`[WPO] Day loaded: ${dayLoading} ms`)
        Analytics.send({
          category: 'ux.performance',
          action: 'load_day',
          label: dayLoading.toString()
        })
        await checkNecessaryParameters()
        loaded.value = true
        ctx.emit('loaded', {
          loaded: true,
          dayId: dayId.value
        })
        selectLesson(isDesktop ? 0 : -1)

        FirebasePerformance.record({
          traceName: 'load_day',
          startTime: startLoading,
          options: {
            attributes: {
              userCourseStreamId: streamId.toString(),
              streamId: (stream.value?.streamId || '').toString(),
              courseId: (course.value?.id || '').toString(),
              courseTitle: course.value?.title || '',
              dayId: dayId.value.toString(),
              stageId: stageId.value.toString()
            }
          }
        })

        setTimeout(() => nextTick(resizeColumns), 1000)
      }
    }

    function onPlayerReady () {
      playerReady.value = true
    }

    onMounted(() => {
      init()
    })

    watch(dayId, (nDayId, oDayId) => {
      if (nDayId && nDayId !== oDayId) {
        ctx.emit('loaded', {
          loaded: true,
          dayId: nDayId
        })
      }
    })

    watch(filteredLessons, () => {
      nextTick(resizeColumns)
    })

    watch(() => dayStatus.value?.id, async (dayStatusId: number | undefined, prev: number | undefined) => {
      if (dayStatusId && stream.value && prev) {
        nextDayLoaded.value = false
        ctx.emit('nextDayLoaded', false)
        const sId = streamId.value
        await store.dispatch('day/fetchDayCached', { courseId: stream.value.courseId, stageId: stageId.value, dayId: dayId.value })
        await store.dispatch('day/fetchProgress', { streamId: sId, dayStatusId })
        nextDayLoaded.value = true
        ctx.emit('nextDayLoaded', true)
      }
    })

    watch(progress, () => {
      if ((dayStatus.value ? dayStatus.value.status.toString() : '') === DAY_STATUS.ENDED) {
        saveLocationToContinue(true)
        return
      }
      if (progress.value === 100) {
        onFinishDay()
          .then(() => store.dispatch('stream/fetchStatuses', { id: streamId.value, expand: 'stages,days,homeworks' }))
      }
    }, {
      immediate: true
    })

    return {
      ...data,
      bannersTop,
      bannersBottom,
      bannerBeforeLessons,
      bannerInsteadVideo,
      bannersMobileBottom,
      bannerMobileInsteadVideo,
      bannerMobileAfterVideo,
      bannersPlatforms,
      bannersPlacements,
      bannersPosition,
      playerReady,
      isDesktop,
      platform: display.getPlatform(),
      playerIsFullPage,
      refCourse,
      refPlayerWrapper,
      refLessonsWrapper,
      refDayLessons,
      playPlayer,
      autoPlayNextVideo,
      autoPlayNextVideoMobile,
      loaded,
      course,
      filteredLessons,
      currentLesson,
      currentLessonIndex,
      homeworks,
      progress,
      isShowMaterials,
      dayId,
      courseId,
      streamId,
      showVideo,
      showBannerInsteadVideo,
      isHomeworkDisabled,
      onBannerInsteadVideoEnded,
      selectLesson,
      openVideo,
      closeVideo,
      onPlayerReady
    }
  }
})
