import { ref, computed, onBeforeUnmount, watch } from 'vue'
import Analytics from '@web/services/Analytics/Analytics'
import * as AnalyticEvents from '@web/services/Analytics/events'
import isEmpty from 'lodash.isempty'
import DAY_STATUS from '@web/consts/DayStatus'
import mergeCourseOptionsWithUserOptions from '@web/common/mergeCourseOptionsWithUserOptions'
import filterLessonByOptions from '@web/common/filterLessonByOptions'
import Logger from '@web/common/Logger'
import { DayStatus } from '@web/store/types/modules/stream'
import { useStore } from '@web/store'
import { Router, RouteLocationNormalizedLoaded } from 'vue-router'
import { IS_IOS, IS_MOBILE, IS_WEB } from '@web/common/config'
import { getUrlWithAuthParams } from '@web/common/Utils'
import { isOfflineEnable, timeoutSaveStatistic } from '@web/common/ExternalConfig/getters'
import { DayLesson } from '@web/store/types/modules/day'
import getBaseUrl from '@web/common/getBaseUrl'
import sentry from '@web/common/sentry'
import { useDayBanners } from '@web/composition/useBanners'
import LocalStorage from '@web/common/LocalStorage'
import STAGE_STATUS from '@web/consts/StageStatus'

const store = useStore()
const CONTINUE_TRAINING_KEY = 'acProject_continue_training'

export const isLockedLesson = (isStreamDemo: boolean) => (type: 'simple' | 'analysis' | 'default', lessonSetLesson: LessonSetLesson) => {
  if (!isStreamDemo) {
    return false
  }

  if (type === 'simple') {
    return !lessonSetLesson.videoDemoAccessible
  } else if (type === 'analysis') {
    return !lessonSetLesson.descriptionVideoDemoAccessible
  }
  return false
}

function useDay ({ route, router }: { router: Router; route: RouteLocationNormalizedLoaded }) {
  const modal = ref(false)
  const loaded = ref(true)
  const currentLessonIndex = ref(0)
  const showOrientationVideos = ref({})

  const streamId = computed<number>(() => Number(router.currentRoute.value.params.streamId))
  const stream = computed(() => store.getters['stream/getStreamById'](streamId.value))
  const chatChannelId = computed(() => stream.value?.chatChannelId)
  const course = computed<Course | undefined>(() => {
    if (!stream.value) {
      return undefined
    }
    return store.getters['course/getCourseById'](stream.value.courseId)
  })
  const dayId = ref(Number(route.params.dayId))
  const stageId = ref(Number(route.params.stageId))
  const dayNumber = computed<number>(() => Number(router.currentRoute.value.params.dayNumber))
  const day = computed(() => store.getters['day/getDayById'](dayId.value))

  watch(() => router.currentRoute.value.params.dayId, id => {
    dayId.value = Number(id)
  })
  watch(() => router.currentRoute.value.params.stageId, id => {
    stageId.value = Number(id)
  })

  const dayLessons = computed(() => store.getters['day/getLessonsByDayId'](dayId.value))
  type _lessons = (DayLesson & { videoUrl: string; isLocked: boolean })[]
  const lessons = computed<_lessons>(() => {
    const _isLocked = isLockedLesson(Boolean(stream.value?.isDemo))
    return dayLessons.value.lessons
      .filter(item => {
        if (item.video.status !== 3) {
          Logger.warn(`Видео не сконвертировано. courseId = ${course.value?.id}, dayId = ${dayId.value}, lessonTitle ${item.lesson.title}, lessonId = ${item.lesson.id}, videoId = ${item.video.id}, videoStatus = ${item.video.status}`)
          return false
        }
        return true
      })
      .map(l => {
        const isLocked = _isLocked(l.meta.type, l.lessonSetLesson)

        let url = getUrlWithAuthParams(l.video.m3u8 || '')
        const MP4_DEFAULT_RESOLUTION = 480
        if (!IS_IOS && l.video?.mp4?.[MP4_DEFAULT_RESOLUTION]) {
          // url = getUrlWithAuthParams(lesson.video.mp4[MP4_DEFAULT_RESOLUTION])
        }
        if (isOfflineEnable() && IS_MOBILE) {
          url = window.Ionic.WebView.convertFileSrc(l.video.localUrl) || url
        }

        if (isLocked) {
          url = 'video-offer.mp4'

          if (stream.value) {
            const course = store.getters['course/getCourseById'](stream.value.courseId)
            if (course && course.demoVideo?.mp4?.['720']) {
              url = getUrlWithAuthParams(getBaseUrl(course.demoVideo.mp4?.['720'])) || ''
            }
          }
        }

        return {
          ...l,
          videoUrl: url,
          isLocked
        }
      })
  })

  const filters = computed<LessonFilters>(() => {
    const defaultFilters: LessonFilters = {}

    if (dayLessons.value?.hasDifferentLessonOrientation) {
      defaultFilters.orientation = IS_WEB ? 'horizontal' : 'vertical'
    }

    if (dayLessons.value?.hasDifferentLessonType) {
      defaultFilters.analysis = true
      defaultFilters.simple = true
    }
    const userFilters: LessonFilters | undefined = store.getters['stream/getFiltersByStreamId'](streamId.value)
    const _filters = {}

    Object.keys(defaultFilters).forEach(filter => {
      if (userFilters) {
        _filters[filter] = Object.prototype.hasOwnProperty.call(userFilters, filter) ? userFilters?.[filter] : defaultFilters[filter]
      } else {
        _filters[filter] = defaultFilters[filter]
      }
    })

    return _filters
  })

  const options = computed(() => {
    if (!day.value || !stream.value) {
      return []
    }
    return mergeCourseOptionsWithUserOptions({
      courseOptions: day.value.courseOptions,
      filters: stream.value.filters
    }).sort((o1, o2) => o1.userName > o2.userName ? -1 : 1)
  })

  const filteredLessons = computed(() => {
    return lessons.value
      .filter(item => {
        if (!filterLessonByOptions(options.value)(item.lesson.trainingOptions)) {
          return false
        }

        let isFilteredByOrientation = true
        let isFilteredByType = true
        if (dayLessons.value?.hasDifferentLessonOrientation) {
          isFilteredByOrientation = filters.value.orientation === item.meta.orientation
        }
        if (dayLessons.value?.hasDifferentLessonType) {
          isFilteredByType = Boolean(filters.value[item.meta.type]) || item.meta.type === 'default'
        }
        return isFilteredByOrientation && isFilteredByType
      })
  })

  const videoCount = computed(() => Array.isArray(filteredLessons.value) ? filteredLessons.value.length : 0)

  const isShowBtnCourseOptions = computed(() => options.value.length > 0 || !isEmpty(filters.value))
  const lessonIndex = computed(() => currentLessonIndex.value < filteredLessons.value.length ? currentLessonIndex.value : 0)

  const title = computed(() => day.value?.title)

  const currentLesson = computed(() => filteredLessons.value[lessonIndex.value])
  const dayStatus = computed<DayStatus | undefined>(() => store.getters['stream/getDayStatus']({ streamId: streamId.value, dayId: dayId.value }))
  const progress = computed(() => {
    if (!dayStatus.value || dayStatus.value.id === 0) {
      return 0
    }
    const dayStatistic = store.getters['stream/getDayStatistic'](dayStatus.value.id)
    if (!dayStatistic) {
      return 0
    }
    const viewedSeconds = dayStatistic.viewedSeconds || 0

    const finishTotalTime = day.value ? Math.max(day.value?.finishTotalTime, 1) : 1
    return Math.min(Math.ceil(viewedSeconds / finishTotalTime * 100), 100)
  })
  const homeworks = computed(() => {
    // Если надо будет убрать домашку из дня
    // if (course.value?.gamificationEnabled && !stream.value?.courseFullAccess) {
    //   if (dayStatus.value?.status !== DAY_STATUS.ENDED) {
    //     return []
    //   }
    // }
    return store.getters['homework/getHomeworksByDayIdAndStreamId'](dayId.value, streamId.value)
  })
  const videoAnalytics = AnalyticEvents.videoInDay()

  /**
   * @param {number} time - в мс
   */
  function receiveVideoTime (time) {
    if (!currentLesson.value || !dayStatus.value) {
      return
    }
    store.commit('stream/appendTimeSpent', {
      streamId: streamId.value,
      stageId: stageId.value,
      dayId: dayId.value,
      time,
      lessonId: currentLesson.value.lessonSetLesson.lessonSetLessonId,
      dayStatusId: dayStatus.value.id,
      dayStatus: dayStatus.value.status
    })
  }

  function updateVideoProgress (videoProgress: number) {
    if (!currentLesson.value) {
      return
    }

    if (videoProgress >= 85) {
      videoAnalytics.complete()
    }

    videoAnalytics.progress(videoProgress)
  }

  function videoPlay () {
    if (!currentLesson.value || !dayStatus.value) {
      return
    }
    store.commit('stream/appendPlay', {
      streamId: streamId.value,
      dayId: dayId.value,
      lessonId: currentLesson.value.lessonSetLesson.lessonSetLessonId,
      dayStatusId: dayStatus.value.id,
      dayStatus: dayStatus.value.status
    })

    videoAnalytics.start()
  }
  function nextVideo () {
    if (currentLessonIndex.value < filteredLessons.value.length - 1) {
      currentLessonIndex.value += 1
    }
  }
  function videoEnded () {
    videoAnalytics.complete()
  }

  function firstPlay () {
    videoAnalytics.firstInDay()
  }

  function setPageData () {
    if (stream.value && course.value && day.value) {
      const params = {
        courseTitle: course.value.title,
        courseId: String(course.value.id),
        streamId: String(stream.value.streamId),
        userCourseStreamId: String(stream.value.id),
        stageDayId: String(day.value.id),
        dayNumber: String(day.value.dayNumber),
        dayTitle: day.value.title,
        stageId: String(day.value.stageId),
        stageTitle: '',
        startDate: String(stream.value.startDate),
        endDate: String(stream.value.endDate)
      }
      const stage = store.getters['stage/getStageById'](day.value.stageId)
      if (stage) {
        params.stageTitle = stage.title
      }
      Analytics.setPageData(params)
    }
  }

  function saveLocationToContinue (isFinished) {
    const _streamId = streamId.value || router.currentRoute.value.params.streamId
    let route: unknown = {
      name: 'home-courses'
    }

    if (_streamId) {
      route = {
        name: 'calendar',
        params: {
          streamId: _streamId.toString()
        }
      }
    }

    if (!course.value?.gamificationEnabled) {
      if (isFinished || (dayStatus.value ? dayStatus.value.status.toString() : '') === DAY_STATUS.ENDED) {
        if (router.currentRoute.value.params.dayNumber && stream.value) {
          const nextDay = store.getters['day/getDayByDayNumberAndCourseId']({ dayNumber: dayNumber.value + 1, courseId: stream.value.courseId })
          if (nextDay && !nextDay.dayOfRest) {
            const stage = store.getters['stream/getStageStatus']({
              streamId: streamId.value,
              stageId: nextDay.stageId
            })
            if (stage?.status === STAGE_STATUS.OPEN) {
              route = {
                name: 'day',
                params: {
                  ...router.currentRoute.value.params,
                  streamId: streamId.value.toString(),
                  dayNumber: nextDay.dayNumber
                }
              }
            }
          }
        }
      } else {
        route = {
          name: 'day',
          meta: router.currentRoute.value.meta,
          params: router.currentRoute.value.params
        }
      }
    } else {
      if (!isFinished) {
        route = {
          name: 'day',
          meta: router.currentRoute.value.meta,
          params: router.currentRoute.value.params
        }
      }
    }

    if (route) {
      LocalStorage.set(CONTINUE_TRAINING_KEY, JSON.stringify(route))
    }
  }

  async function onOpenDay () {
    const sId = streamId.value

    setPageData()

    if ((!dayStatus.value || dayStatus.value.status === DAY_STATUS.NOT_STARTED)) {
      await store.dispatch('day/startDay', { streamId: sId, stageId: stageId.value, dayId: dayId.value })

      AnalyticEvents.courseDay({ type: 'start' })
    }

    if (dayStatus.value && dayStatus.value.id !== 0) {
      store.dispatch('day/fetchProgress', {
        streamId: sId,
        dayStatusId: dayStatus.value.id
      })
    }

    Analytics.send({
      action: 'open_day'
    })
    AnalyticEvents.firstOpenStage()
    store.dispatch('userScore/amount')

    saveLocationToContinue(false)
  }

  async function onFinishDay () {
    try {
      const sId = streamId.value
      if (!dayStatus.value || dayStatus.value.status === DAY_STATUS.ENDED || !sId) {
        return
      }

      AnalyticEvents.courseDay({ type: 'finish' })

      await store.dispatch('day/finishDay', { streamId: sId, stageId: stageId.value, dayId: dayId.value })
      await store.dispatch('userScore/amount')
      saveLocationToContinue(true)
    } catch (e) {
      return e
    }
  }

  function openLessonFilterModal () {
    Analytics.send({
      category: 'ux.click',
      action: 'modal_filters'
    })
    modal.value = true
  }

  function goToChat (id) {
    store.commit('router/setReturnUrl', route.fullPath)
    router.push({ name: 'chat', params: { chatId: id } })
  }

  async function fetchData () {
    const sId = streamId.value
    if (!stream.value || !sId) {
      return
    }
    await store.dispatch('day/fetchDayCached', { courseId: stream.value.courseId, stageId: stageId.value, dayId: dayId.value })

    await store.dispatch('stream/fetchStatuses', {
      id: sId,
      expand: 'days,lessons'
    })
      .then(onOpenDay)

    if (dayStatus.value) {
      store.dispatch('day/fetchProgress', { streamId: sId, dayStatusId: dayStatus.value?.id })
    }
  }
  async function fetchDataForce () {
    const sId = streamId.value
    if (!stream.value || !sId) {
      return
    }
    await store.dispatch('day/fetchDay', { courseId: stream.value.courseId, stageId: stageId.value, dayId: dayId.value })

    await store.dispatch('stream/fetchStatuses', {
      id: sId,
      expand: 'stages,days,homeworks'
    })
      .then(onOpenDay)

    if (dayStatus.value) {
      store.dispatch('day/fetchProgress', { streamId: sId, dayStatusId: dayStatus.value?.id })
    }
  }
  function setAltDayIdAndAltStageId () {
    if (!stream.value || !dayNumber.value) {
      return
    }
    const day = store.getters['day/getDayByDayNumberAndCourseId']({ dayNumber: dayNumber.value, courseId: stream.value.courseId })
    if (!day) {
      return
    }
    dayId.value = day.id
    stageId.value = day.stageId
  }

  function checkNecessaryParameters () {
    const pageName = router.currentRoute.value.name
    if (!dayNumber.value && IS_WEB && stream.value && day.value && pageName !== 'day') {
      return router.push({
        name: 'day',
        params: {
          courseId: String(stream.value.courseId),
          stageId: String(stageId.value),
          dayId: String(dayId.value),
          dayNumber: String(day.value.dayNumber),
          streamId: String(streamId.value)
        }
      })
    }

    if (!store.getters['stream/getStreamById'](streamId.value) && pageName !== 'courses') {
      return router.push({ name: 'courses' })
    }

    if (pageName !== 'calendar') {
      if (!store.getters['stage/getStageById'](stageId.value)) {
        return router.push({
          name: 'calendar',
          params: {
            courseId: String(stream.value?.courseId),
            streamId: String(stream.value?.id)
          }
        })
      }
      if ((day.value && day.value?.id !== dayId.value) || !day.value?.id) {
        return router.push({
          name: 'calendar',
          params: {
            courseId: String(stream.value?.courseId),
            streamId: String(stream.value?.id)
          }
        })
      }
    }
  }

  let timerId
  function sendStatistic () {
    const TIMEOUT_SAVE_STATISTIC = timeoutSaveStatistic() || 5000
    const statistic = store.state.stream.statistic
    timerId = setInterval(() => {
      if (!isEmpty(statistic)) {
        store.dispatch('stream/sendStatistic')
          .catch(sentry.captureException)
      }
    }, TIMEOUT_SAVE_STATISTIC)
  }

  onBeforeUnmount(() => {
    Analytics.clearPageData()
    if (timerId) {
      clearInterval(timerId)
    }
  })

  // TODO Пока отлючить событие course_day_progress
  // watch(progress, (newProgress) => {
  //   if (!course.value || !day.value) {
  //     return
  //   }
  //
  //   AnalyticEvents.courseDayProgress()({
  //     courseDayId: day.value.id.toString(),
  //     courseDayNumber: day.value.dayNumber.toString(),
  //     progress: newProgress
  //   })
  // })

  watch(currentLesson, (newLesson) => {
    if (!newLesson || !day.value) {
      return
    }

    videoAnalytics.init({
      lessonTitle: newLesson.lesson.title,
      lessonId: newLesson.lesson.id.toString(),
      videoType: newLesson.meta.type,
      videoOrientation: newLesson.meta.orientation
    })
  })

  return {
    modal,
    loaded,
    currentLessonIndex,
    showOrientationVideos,

    streamId,
    stream,
    chatChannelId,
    course,
    dayId,
    dayNumber,
    day,
    stageId,
    lessons,
    filteredLessons,
    filters,
    title,
    videoCount,
    options,
    isShowBtnCourseOptions,
    lessonIndex,
    currentLesson,
    progress,
    homeworks,
    dayStatus,

    receiveVideoTime,
    updateVideoProgress,
    videoPlay,
    videoEnded,
    nextVideo,
    firstPlay,
    onOpenDay,
    onFinishDay,
    saveLocationToContinue,
    openLessonFilterModal,
    goToChat,
    fetchData,
    fetchDataForce,
    setAltDayIdAndAltStageId,
    checkNecessaryParameters,
    videoAnalytics,
    setPageData,
    sendStatistic,
    ...useDayBanners({
      stream,
      day
    })
  }
}

export default useDay
