
import { defineComponent } from 'vue'

import useSettingModal from '@web/composition/useSettingModal'

import AcCheckbox from '@ui-kit/components/AcCheckbox.vue'
import AcSelect from '@ui-kit/components/AcSelect.vue'

export default defineComponent({
  name: 'DayFilterModalContent',
  components: {
    AcSelect,
    AcCheckbox
  },
  props: {
    options: {
      type: Array as () => (CourseOptions & { value: TrainingLessonOptionValue})[],
      required: true
    },
    streamId: {
      type: Number,
      required: true
    },
    filters: {
      type: Object as () => LessonFilters,
      required: true
    }
  },
  setup (props) {
    const data = useSettingModal({
      streamId: props.streamId
    })

    return {
      ...data
    }
  }
})
