
import { computed, defineComponent, ref } from 'vue'

import AcMenu from '@ui-kit/components/AcMenu.vue'
import DayFilterModalContent from '@web/components.v2/Day/FilterModal/Content.vue'
import Analytics from '@web/services/Analytics/Analytics'

export default defineComponent({
  name: 'DayFilterModal',
  components: {
    DayFilterModalContent,
    AcMenu
  },
  props: {
    options: {
      type: Array as () => (CourseOptions & { value: TrainingLessonOptionValue})[],
      required: true
    },
    streamId: {
      type: Number,
      required: true
    },
    filters: {
      type: Object as () => LessonFilters,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup () {
    const showModal = ref(false)
    const isMobile = computed(() => window.innerWidth <= 768)

    function handlerShowModal () {
      Analytics.send({
        category: 'ux.click',
        action: 'modal_filters'
      })
      showModal.value = true
    }

    function handlerCloseModal () {
      showModal.value = false
    }

    return {
      showModal,
      isMobile,
      handlerShowModal,
      handlerCloseModal
    }
  }
})
