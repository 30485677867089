
import { defineComponent, ref, onMounted, onBeforeUnmount, watch, computed, SetupContext, Ref } from 'vue'
import Swal from 'sweetalert2'
import BottomMenu from './BottomMenu.vue'
// eslint-ignore-next-line
import videojs, { VideoJsPlayer } from 'video.js'
import '@videojs/http-streaming'
import 'videojs-playbackrate-adjuster'
import videojsLangRu from 'video.js/dist/lang/ru.json'
import videojsLangEn from 'video.js/dist/lang/en.json'
import videojsLangEs from 'video.js/dist/lang/es.json'
import PlayerHeader from './components/PlayerHeader'
import PlayerRepeat from './components/PlayerRepeat'
import PlayerControls from './components/PlayerControls'
import PlayerFooter from './components/PlayerFooter'
import PlayerSettingsMenu from './components/PlayerSettingsMenu'
import { performanceEnd, getUrlWithAuthParams, isOffline } from '@web/common/Utils'
import getBaseUrl from '@web/common/getBaseUrl'
import i18n from '@web/plugins/i18n'
import locale from '@web/common/locale'
import LocalStorage from '@web/common/LocalStorage'
// после обновления до @typeform/embed@1.0.0+ удалить
import * as typeformEmbed from '@typeform/embed'
import localforage from 'localforage'
import Logger from '@web/common/Logger'
import { useStore } from '@web/store'
import Analytics from '@web/services/Analytics/Analytics'
import * as AnalyticEvents from '@web/services/Analytics/events'
import { DayLesson } from '@web/store/types/modules/day'

videojs.registerComponent('PlayerHeader', PlayerHeader)
videojs.registerComponent('PlayerRepeat', PlayerRepeat)
videojs.registerComponent('PlayerControls', PlayerControls)
videojs.registerComponent('PlayerFooter', PlayerFooter)
videojs.registerComponent('PlayerSettingsMenu', PlayerSettingsMenu)

const emits = {
  close: (): boolean => true,
  offer: (): boolean => true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update: (payload: number): boolean => true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  timeupdate: (payload: number): boolean => true,
  play: (): boolean => true,
  firstPlay: (): boolean => true,
  ended: (): boolean => true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  progress: (payload: number): boolean => true,
  ready: (): boolean => true
}

const useTimer = ({ emit }: { emit: SetupContext<typeof emits>['emit'] }) => {
  let time = 0

  const timeupdate = () => {
    if (time > 0) {
      emit('timeupdate', new Date().getTime() - time)
    }
    time = 0
  }

  const timer = setInterval(() => {
    if (time > 0) {
      timeupdate()
      time = new Date().getTime()
    }
  }, 1000)

  const initTimer = () => {
    time = new Date().getTime()
  }

  return {
    timer,
    initTimer,
    timeupdate
  }
}

const getProgress = (player: VideoJsPlayer): number => {
  return (Number(player.currentTime()) / Number(player.duration())) * 100
}

const initEvents = ({ player, emit }: { player: VideoJsPlayer; emit: SetupContext<typeof emits>['emit'] }) => {
  let autoplay = false

  const {
    initTimer,
    timeupdate,
    timer
  } = useTimer({ emit })

  const startPlay = () => {
    emit('play')
    initTimer()
    if (!autoplay) {
      emit('firstPlay')
    }
    autoplay = true
  }

  const endPlay = () => {
    timeupdate()
  }

  const ended = () => {
    emit('ended')
  }

  player.on('play', startPlay)
  player.on('pause', endPlay)
  player.on('ended', ended)
  player.on('timeupdate', () => {
    const progress = getProgress(player)
    if (!isNaN(progress)) {
      emit('progress', progress)
    }
  })

  return timer
}

interface Coords {
  x: number;
  y: number;
}
function getCoords (event: TouchEvent): Coords {
  return {
    x: event?.changedTouches[0].clientX,
    y: event?.changedTouches[0].clientY
  }
}
function initTouchEvents (player: HTMLVideoElement, wrapper: HTMLDivElement | null, callback: () => void, playerClasses: Ref) {
  let startCoords: Coords
  let startTime
  const disableTouch = () : boolean => (playerClasses.value.includes('contain') && !playerClasses.value.includes('-fullPage'))

  player.addEventListener('touchstart', e => {
    if (disableTouch()) return
    startCoords = getCoords(e)
    startTime = performance.now()
  })

  player.addEventListener('touchmove', e => {
    if (disableTouch()) return
    const endCoords = getCoords(e)
    const dis = endCoords.y - startCoords.y
    const offset = Math.abs(endCoords.x - startCoords.x)
    if (offset > Math.abs(dis)) {
      return
    }

    if (wrapper) {
      wrapper.style.transform = `translate3d(0px, ${dis}px, 0px) scale(1)`
    }
    e.stopPropagation()
  })

  player.addEventListener('touchend', e => {
    if (disableTouch()) return
    const endCoords = getCoords(e)
    let dis = Math.abs(endCoords.y - startCoords.y)
    const offset = Math.abs(endCoords.x - startCoords.x)
    if (offset > dis) {
      return
    }

    const time = performanceEnd(startTime)
    if (time < 300) {
      dis = dis * Math.max(Math.ceil(time / 10), 3)
    }

    if (dis > window.innerHeight / 8) { // Смещение на 8 часть от высоты экрана
      callback()
    }
    if (wrapper) {
      wrapper.style.transform = 'none'
    }
    e.stopPropagation()
  })
}

function sendAnalyticsClick (event: Dictionary<string>) {
  AnalyticEvents.click({
    element_type: 'pretty-player',
    ...event
  })
}

interface PrettyPlayer {
  video: {
    src: string;
    title: string;
    subtitle: string;
    subs: Subs[];
    index: number;
    orientation: string;
    isLocked: boolean;
    isOffline: boolean;
    surveyFormId: number | null;
    surveyFormUrl: string;
  };
}
async function initTypeform ({ url, typeFormId, userId, lessonTitle }: { url: string; typeFormId: number; userId: number; lessonTitle: string }): Promise<unknown> {
  const KEY = `lesson_survey_${userId}_${typeFormId}`
  try {
    const isOpened = await localforage.getItem(KEY)
    if (isOpened) {
      return
    } else {
      await localforage.setItem(KEY, true)
    }
  } catch (e) {
    Logger.error(e)
  }

  return new Promise<void>(resolve => {
    fetch(getUrlWithAuthParams(url).replace('redirect-url', 'get-url'))
      .then(r => r.json())
      .then(response => {
        if (response.url) {
          const urlInstance = new URL(response.url)
          urlInstance.searchParams.set('utm_source', lessonTitle)
          const reference = typeformEmbed.makePopup(
            urlInstance.toString(),
            {
              mode: 'popup',
              hideScrollbars: true,
              onSubmit: () => {
                reference.close()
                return resolve()
              },
              // этот хук не работает в нашей версии, появился в версии 1.0.0, которая сейчас в пре-релизном состоянии
              onClose: () => {
                return resolve()
              }
            }
          )

          reference.open()
        }
      })
  })
}

export default defineComponent({
  name: 'PrettyPlayer',
  props: {
    lessons: {
      type: Array as () => (DayLesson & { videoUrl: string; isLocked: boolean })[],
      required: true
    },
    lessonIndex: {
      type: Number,
      required: true
    },
    fullPage: {
      type: Boolean,
      default: false
    },
    autoPlay: {
      type: Boolean,
      default: true
    },
    tablet: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BottomMenu
  },
  emits,
  setup (props, ctx) {
    const store = useStore()
    let player: VideoJsPlayer
    const playerNode = ref<HTMLVideoElement | null>(null)
    const refPrettyPlayer = ref<HTMLDivElement | null>(null)
    let count = 0
    const backgroundSize = ref<'cover' | 'contain'>('contain')
    const autoNext = ref(true)
    const playerReady = ref(false)

    const lessons = props.lessons

    const videos: PrettyPlayer['video'][] = lessons.map((lesson, i) => {
      if (lesson.lesson.id !== lessons[i - 1]?.lesson.id) {
        count++
      }

      return {
        src: lesson.videoUrl,
        title: lesson.lesson.title,
        subtitle: lesson.lesson.subtitle,
        subs: lesson.video.subs || [],
        index: count,
        orientation: lesson.isLocked ? 'vertical' : lesson.meta.orientation,
        isLocked: lesson.isLocked,
        isOffline: !!lesson.video.localUrl,
        surveyFormId: lesson.lessonSetLesson?.surveyFormId || 0,
        surveyFormUrl: lesson.lessonSetLesson?.surveyFormUrl || ''
      }
    })

    const currentIndex = ref(props.lessonIndex)
    const currentVideo = computed(() => videos[currentIndex.value])

    function close () {
      sendAnalyticsClick({

        element_id: 'close',

        element_name: 'Кнопка закрыть плеер'
      })

      if (player && currentVideo.value) {
        const progress = getProgress(player)
        if (progress >= 50 && currentVideo.value?.surveyFormUrl && currentVideo.value?.surveyFormId) {
          initTypeform({
            typeFormId: currentVideo.value.surveyFormId,
            url: currentVideo.value.surveyFormUrl,
            userId: store.getters['user/user'].id,
            lessonTitle: currentVideo.value.title
          })
        }
        ctx.emit('close')
      }

      ctx.emit('close')
    }

    const classes = computed(() => {
      return [currentVideo.value.orientation, currentVideo.value.isLocked ? '-locked' : '', backgroundSize.value, props.fullPage ? '-fullPage' : '']
    })

    function prev () {
      if (currentIndex.value < 1) {
        currentIndex.value = 0
        return
      } else {
        currentIndex.value--

        const prevVideo = videos[currentIndex.value + 1]
        if (currentVideo.value.isLocked && prevVideo?.isLocked) {
          prev()
        }
      }

      if (player?.el()) {
        player?.src({ src: videos[currentIndex.value].src })
        player?.play()
      }
    }

    function next () {
      if (currentIndex.value >= videos.length - 1) {
        currentIndex.value = videos.length - 1
        return
      } else {
        currentIndex.value++

        const prevVideo = videos[currentIndex.value - 1]
        if (currentVideo.value.isLocked && prevVideo?.isLocked) {
          next()
        }
      }

      if (player?.el()) {
        player?.src({ src: videos[currentIndex.value].src })
        player?.play()
      }
    }

    // Fullscreen
    const KEY_STORE_BG_SIZE = 'pp_bg_size'
    backgroundSize.value = LocalStorage.get(KEY_STORE_BG_SIZE) ? String(LocalStorage.get(KEY_STORE_BG_SIZE)) as 'cover' | 'contain' : 'contain'
    function onClickSize () {
      backgroundSize.value = backgroundSize.value === 'cover' ? 'contain' : 'cover'
      if (!props.fullPage && playerReady.value) {
        if (backgroundSize.value === 'cover') {
          if (player.supportsFullScreen()) {
            player.requestFullscreen()
          } else {
            player.enterFullWindow()
          }
        } else {
          if (player.supportsFullScreen()) {
            player.exitFullscreen()
          } else {
            player.exitFullWindow()
          }
        }
      }
    }

    // Autonext
    const KEY_STORE_AUTONEXT = 'pp_auto_next'
    autoNext.value = LocalStorage.get(KEY_STORE_AUTONEXT) ? LocalStorage.get(KEY_STORE_AUTONEXT) === 'true' : true
    function onClickAutoNext () {
      autoNext.value = !autoNext.value
      LocalStorage.set(KEY_STORE_AUTONEXT, String(autoNext.value))

      Swal.fire({
        icon: 'success',
        title: i18n.global.t(`player.autoNextSwitch.${autoNext.value ? 'enabled' : 'disabled'}`),
        timer: 2000,
        showConfirmButton: false
      })
    }

    // Bottom menu
    const isOpenMenu = ref(false)

    // Subtitles
    const subsEl = ref<(HTMLTrackElement | undefined)[]>([])
    const KEY_STORE_SUBS_INDEX = 'pp_sub_active'
    const subs = computed(() => currentVideo.value?.subs)
    const subActive = ref<string>(typeof LocalStorage.get(KEY_STORE_SUBS_INDEX) === 'string' ? String(LocalStorage.get(KEY_STORE_SUBS_INDEX)) : 'ru-RU')

    let isSubsOn = false
    if (LocalStorage.get(KEY_STORE_SUBS_INDEX)) {
      isSubsOn = true
    }
    function onSubs (lang: string = subActive.value) {
      const tracks = player?.textTracks() || []
      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i]
        track.mode = 'hidden'
        if (track.language && track.language === lang) {
          track.mode = 'showing'
          if (!isSubsOn) {
            Analytics.send({
              category: 'subtitles_activity',
              action: 'subtitles_on',
              label: lang
            })
          } else {
            Analytics.send({
              category: 'subtitles_activity',
              action: 'subtitles_change',
              label: lang
            })
          }
        }
      }
    }

    function offSubs () {
      const tracks = player?.textTracks() || []
      for (let i = 0; i < tracks.length; i++) {
        tracks[i].mode = 'hidden'
        Analytics.send({
          category: 'subtitles_activity',
          action: 'subtitles_off'
        })
      }
    }
    function initSubs () {
      if (!player?.remoteTextTracks()) {
        return
      }

      for (let i = 0; i < player?.remoteTextTracks().length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        player?.removeRemoteTextTrack(player?.remoteTextTracks()[i])
      }
      subsEl.value.forEach(el => el ? player?.removeRemoteTextTrack(el) : null)
      subsEl.value = []
      if (player && subs.value.length) {
        subs.value.forEach(ss => {
          subsEl.value.push(player?.addRemoteTextTrack({
            id: ss.languageCode,
            src: getUrlWithAuthParams(getBaseUrl(ss.url)),
            label: ss.label,
            language: ss.languageCode
          }, true))
        })

        onSubs()
      }
    }

    function onClickSubs (lang: string) {
      onSubs(lang)
      subActive.value = lang
      LocalStorage.set(KEY_STORE_SUBS_INDEX, lang)
    }

    function onClickOffSubs () {
      offSubs()
      subActive.value = ''
      LocalStorage.set(KEY_STORE_SUBS_INDEX, '')
    }

    // PlaybackRates
    const playbackRateIsSupported = ref(false)
    const KEY_STORE_PLAYBACK_RATE = 'pp_playback_rate'
    const currentPlaybackRate = ref<number>(LocalStorage.get(KEY_STORE_PLAYBACK_RATE) ? Number(LocalStorage.get(KEY_STORE_PLAYBACK_RATE)) : 1)
    const playbackRates = [0.5, 1, 1.25, 1.5, 2]
    function onClickPlayBackRates (rate: number) {
      currentPlaybackRate.value = rate
      LocalStorage.set(KEY_STORE_PLAYBACK_RATE, String(rate))
      player?.playbackRate(rate)
    }

    const KEY_STORE_AUDIO_TRACK = 'pp_audio_track'
    const currentAudioTrack = ref<string>(LocalStorage.get(KEY_STORE_AUDIO_TRACK) ? String(LocalStorage.get(KEY_STORE_AUDIO_TRACK)) : '')
    function onClickAudioTracks (id: string) {
      const audioTracks: videojs.AudioTrackList = player?.tech().audioTracks() as videojs.AudioTrackList || player?.audioTracks() || []
      let audioTrack = audioTracks[0]
      for (let at = 0; at < audioTracks.length; at++) {
        audioTracks[at].enabled = false
        if (audioTracks[at].id === id) {
          audioTrack = audioTracks[at]
          currentAudioTrack.value = audioTrack.id
          LocalStorage.set(KEY_STORE_AUDIO_TRACK, String(audioTrack.id))
        }
      }
      if (audioTrack) {
        audioTrack.enabled = true
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        player?.audioTracks(audioTrack.id)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        player?.tech().audioTracks(audioTrack.id)
      }
    }
    // airPlay
    const hasAirPlayAPISupport = () => Boolean(window.WebKitPlaybackTargetAvailabilityEvent)
    function handlerAirPlay () {
      player?.trigger('airPlayRequested')
    }
    function onTargetAvailabilityChanged () {
      // if (document.querySelectorAll('.vjs-btn-airplay').length) {
      //   document.querySelectorAll('.vjs-btn-airplay')?.forEach(airPlayElement => airPlayElement.classList.remove('-supported'))
      //   if (event.availability === 'available') {
      //     document.querySelectorAll('.vjs-btn-airplay')?.forEach(airPlayElement => airPlayElement.classList.add('-supported'))
      //   }
      // }
    }
    function onAirPlayRequested (player) {
      const mediaEl = player.el().querySelector('video')
      mediaEl?.webkitShowPlaybackTargetPicker()
    }
    function listenForAirPlayEvents (player) {
      player.on('airPlayRequested', onAirPlayRequested.bind(null, player))
    }
    function enableAirPlay (player) {
      listenForAirPlayEvents(player)
    }

    onMounted(() => {
      if (props.fullPage) {
        document.body.style.overflow = 'hidden'
      }

      if (!playerNode.value) {
        return
      }

      const DELAY_BETWEEN_VIDEO = 5000
      player = videojs(playerNode.value, {
        inactivityTimeout: 3000,
        controls: true,
        controlBar: {
          children: ['progressControl', 'remainingTimeDisplay']
        },
        html5: {
          nativeTextTracks: videojs.browser.IS_ANY_SAFARI || videojs.browser.IS_IOS,
          nativeAudioTracks: videojs.browser.IS_ANY_SAFARI || videojs.browser.IS_IOS,
          nativeVideoTracks: videojs.browser.IS_ANY_SAFARI || videojs.browser.IS_IOS,
          vhs: {
            enableLowInitialPlaylist: false,
            overrideNative: !(videojs.browser.IS_ANY_SAFARI || videojs.browser.IS_IOS)
          }
        },
        languages: {
          en: videojsLangEn,
          ru: videojsLangRu,
          es: videojsLangEs
        },
        language: locale.get(),
        playbackRates
      })

      player?.addChild('PlayerHeader', {
        video: currentVideo,
        handlerAirPlay,
        count,
        close,
        openMenu: () => {
          isOpenMenu.value = !isOpenMenu.value
        }
      })
      player?.addChild('PlayerRepeat')
      player?.addChild('PlayerFooter', {
        videos,
        currentIndex,
        prev: () => {
          sendAnalyticsClick({
            element_id: 'prev',
            element_name: i18n.global.t('player.prevVideo')
          })
          prev()
        },
        next: () => {
          sendAnalyticsClick({
            element_id: 'next',
            element_name: i18n.global.t('player.nextVideo')
          })

          next()
        },
        delayBetweenVideo: DELAY_BETWEEN_VIDEO,
        offer: () => {
          sendAnalyticsClick({
            element_id: 'video_offer_button',
            element_name: i18n.global.t('player.offer')
          })

          ctx.emit('offer')
        }
      })
      player?.addChild('PlayerControls', {
        handlerAirPlay,
        openMenu: () => {
          isOpenMenu.value = !isOpenMenu.value
        }
      })
      player?.addChild('PlayerSettingsMenu')

      player?.src({ src: videos[currentIndex.value].src })

      player?.ready(() => {
        // отключение fullscreen по dblclick
        player?.tech().off('dblclick')
        playbackRateIsSupported.value = player?.tech().featuresPlaybackRate || false

        initSubs()

        if (props.autoPlay) {
          player?.play()
        }

        initTouchEvents(player.el().querySelector('video') as HTMLVideoElement, refPrettyPlayer.value, close, classes)
        playerReady.value = true
        ctx.emit('ready')

        enableAirPlay(player)
        if (hasAirPlayAPISupport()) {
          player.el().querySelector('video')?.addEventListener('webkitplaybacktargetavailabilitychanged', onTargetAvailabilityChanged)
        }
      })

      let timer
      player?.on('ended', () => {
        const cb = () => {
          if (autoNext.value) {
            timer = setTimeout(() => {
              next()
            }, DELAY_BETWEEN_VIDEO)
          }
        }

        if (currentVideo.value?.surveyFormUrl && currentVideo.value?.surveyFormId) {
          initTypeform({
            typeFormId: currentVideo.value.surveyFormId,
            url: currentVideo.value.surveyFormUrl,
            userId: store.getters['user/user'].id,
            lessonTitle: currentVideo.value.title
          })
            .then(cb)
        } else {
          cb()
        }
      })

      player?.on('play', () => {
        clearTimeout(timer)
      })

      player?.on('loadeddata', () => {
        onClickPlayBackRates(currentPlaybackRate.value)
        onClickAudioTracks(currentAudioTrack.value)
      })

      if (player) {
        initEvents({ player: player, emit: ctx.emit })
      }

      window.addEventListener('backButtonClick', close)
    })

    const menuItems = computed<Array<MenuItemCheckbox | MenuItemSelect>>(() => {
      const subtitlesItem: MenuItemSelect[] = []
      if (subs.value.length) {
        const subsItems: MenuItemCheckbox[] = []
        subs.value.forEach(ss => {
          subsItems.push({
            type: 'checkbox',
            name: ss.languageCode,
            label: ss.label,
            selected: subActive.value === ss.languageCode,
            value: ss.languageCode
          })
        })
        subtitlesItem.push({
          name: 'subtitles',
          type: 'select',
          label: i18n.global.t('player.subtitles'),
          children: [
            ...subsItems,
            {
              name: 'off',
              type: 'checkbox',
              label: i18n.global.t('player.subsOff'),
              selected: subActive.value === '',
              value: 'off'
            }
          ]
        })
      }

      const playbackRatesItem: MenuItemSelect[] = []
      if (playbackRates.length && playbackRateIsSupported.value) {
        const playbackRateItem: MenuItemCheckbox[] = []
        playbackRates.forEach(rate => {
          playbackRateItem.push({
            type: 'checkbox',
            name: String(rate),
            label: `${rate}x`,
            selected: rate === currentPlaybackRate.value,
            value: String(rate)
          })
        })
        playbackRatesItem.push({
          name: 'playbackRates',
          type: 'select',
          label: i18n.global.t('player.playbackRates'),
          children: playbackRateItem
        })
      }

      const audioTracksItem: MenuItemSelect[] = []
      const audioTracks: videojs.AudioTrackList = player?.tech().audioTracks() as videojs.AudioTrackList || player?.audioTracks() || []
      if (playerReady.value && audioTracks.length > 1) {
        const audioTrackItem: MenuItemCheckbox[] = []
        const audioTracksName = (lang: string) => ({
          eng: 'English',
          rus: 'Русский',
          spa: 'Español'
        }[lang] || lang)
        for (let at = 0; at < audioTracks.length; at++) {
          const audioTrack = audioTracks[at]
          audioTrackItem.push({
            type: 'checkbox',
            name: audioTrack.label,
            label: audioTracksName(audioTrack.label || audioTrack.language),
            selected: audioTrack.enabled && audioTrack.id === (currentAudioTrack.value.length ? currentAudioTrack.value : audioTrack.id),
            value: String(audioTrack.id)
          })
        }

        audioTracksItem.push({
          name: 'audioTracks',
          type: 'select',
          label: i18n.global.t('player.audioTracks'),
          children: audioTrackItem
        })
      }

      // const fullScreenItem: MenuItemCheckbox[] = []
      // fullScreenItem.push({
      //   name: 'fullscreen',
      //   type: 'checkbox',
      //   label: i18n.global.t('player.fullscreen'),
      //   selected: backgroundSize.value === 'cover'
      // })

      return [
        // ...fullScreenItem,
        {
          name: 'autoNext',
          type: 'checkbox',
          label: i18n.global.t('player.autoNext'),
          selected: autoNext.value
        },
        ...subtitlesItem,
        ...playbackRatesItem,
        ...audioTracksItem
      ]
    })

    function onUpdateMenuItem ({ name, value }) {
      switch (name) {
        case 'fullscreen':
          onClickSize()
          break
        case 'autoNext':
          onClickAutoNext()
          break
        case 'subtitles':
          if (value === 'off') {
            onClickOffSubs()
          } else {
            onClickSubs(value)
          }
          break
        case 'playbackRates':
          onClickPlayBackRates(Number(value))
          break
        case 'audioTracks':
          onClickAudioTracks(value)
          break
      }
    }

    onBeforeUnmount(() => {
      if (props.fullPage) {
        document.body.style.overflow = ''
      }

      if (player) {
        player?.dispose()
        if (hasAirPlayAPISupport()) {
          player.el().querySelector('video')?.removeEventListener('webkitplaybacktargetavailabilitychanged', onTargetAvailabilityChanged)
        }
      }

      window.removeEventListener('backButtonClick', close)
    })

    watch(currentIndex, () => {
      ctx.emit('update', currentIndex.value)
    })
    watch(currentVideo, (video: PrettyPlayer['video'], prev: PrettyPlayer['video'] | undefined) => {
      if (isOffline() && !video.isOffline) {
        Swal.fire({
          title: i18n.global.t('error.error'),
          text: i18n.global.t('error.videoOffline'),
          showConfirmButton: false,
          icon: 'error'
        })
      }

      if (prev && video && prev.subs !== video.subs) {
        initSubs()
      }

      Analytics.setPageData({
        videoTitle: video.title,
        videoSubtitle: video.subtitle,
        videoOrientation: video.orientation
      })
    })

    watch(() => props.lessonIndex, () => {
      currentIndex.value = props.lessonIndex
      if (player?.el()) {
        player?.src({ src: videos[currentIndex.value].src })
        player?.play()
      }
    })

    watch(() => props.autoPlay, (newVal) => {
      if (newVal) {
        player?.play()
      }
    })

    return {
      playerReady,
      classes,
      refPrettyPlayer,
      playerNode,
      close,
      video: currentVideo,
      subActive,
      onClickSize,
      onClickAutoNext,
      isOpenMenu,
      subs,
      menuItems,
      onUpdateMenuItem
    }
  }
})
