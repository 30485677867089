import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6a77002"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "day-lessons" }
const _hoisted_2 = { class: "day-lessons_header" }
const _hoisted_3 = { class: "day-lessons_title" }
const _hoisted_4 = { class: "-title" }
const _hoisted_5 = { class: "-count" }
const _hoisted_6 = { class: "day-lessons_filter" }
const _hoisted_7 = { class: "day-lessons_wrapper" }
const _hoisted_8 = { class: "day-lessons_content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ac_progress = _resolveComponent("ac-progress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.$t('videoLessons')) + " ", 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.videoCount), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "filterModal", {}, undefined, true)
        ])
      ]),
      _createVNode(_component_ac_progress, {
        class: "day-lessons_progress",
        "model-value": _ctx.progress
      }, null, 8, ["model-value"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}