import { ref, computed, onBeforeUnmount, Ref, ComputedRef } from 'vue'
import Logger from '@web/common/Logger'
import Locale from '@web/common/locale'
import { DayLesson } from '@web/store/types/modules/day'
import Swal from 'sweetalert2'
import LocalStorage from '@web/common/LocalStorage'
import { getUrlWithAuthParams } from '@web/common/Utils'
import getBaseUrl from '@web/common/getBaseUrl'
import urlParse from 'url-parse'
import i18n from '@web/plugins/i18n'
import Analytics from '@web/services/Analytics/Analytics'
import { useMq } from 'vue3-mq'

interface Callbacks {
  play?: () => void;
  firstPlay?: () => void;
  timeupdate?: (time: number | string) => void;
  progress?: (progress: number | string) => void;
  updateSpeed?: (speed: number | string) => void;
  endPlay?: () => void;
  ended?: () => void;
  playlistItemChanged?: (index: number | string) => void;
}

interface UseTimer {
  callbacks: Callbacks;
}

interface Playerjs {
  api (name: string, params?: unknown): unknown;
}

interface UseInitEvents {
  player: Ref<Playerjs | undefined>;
  callbacks: Callbacks;
}

interface UsePlayer {
  rawVideos: ComputedRef<readonly DayLesson[]>;
  targetId?: string;
  wid?: string | number;
  callbacks: Callbacks;
  lessonIndex: ComputedRef<number | undefined>;
  autoPlayNextVideo: ComputedRef<boolean | undefined>;
}

const STORE_KEY_AUTONEXT = 'ASPlayerJsAutoNext'
const STORE_KEY_PJS_SUBS = 'ASPlayerJsSubtitle'

export function useTimer ({ callbacks }: UseTimer) {
  const time = ref<number>(0)
  const timer = ref<number>(0)

  const timeupdate = () => {
    if (time.value > 0) {
      if (callbacks.timeupdate) {
        callbacks.timeupdate(new Date().getTime() - time.value)
      }
    }
    time.value = 0
  }

  const initTimer = () => {
    time.value = new Date().getTime()
  }

  function stopTimer () {
    clearInterval(timer.value)
  }

  function startTimer (playerNode) {
    timer.value = setInterval(() => {
      if (!playerNode || playerNode.paused) {
        stopTimer()
        return
      }

      if (time.value > 0) {
        timeupdate()
        initTimer()
      }
    }, 1000) as unknown as number
  }

  onBeforeUnmount(() => {
    stopTimer()
  })

  return {
    timer,
    initTimer,
    timeupdate,
    startTimer,
    stopTimer
  }
}

export function useInitEvents ({ player, callbacks }: UseInitEvents) {
  const firstplay = ref(false)
  const autoplay = ref(0)
  const playerReady = ref(false)

  const {
    initTimer,
    timeupdate,
    startTimer,
    stopTimer
  } = useTimer({ callbacks })

  const startPlay = playerNode => {
    if (callbacks.play) {
      callbacks.play()
    }
    initTimer()
    if (!firstplay.value) {
      if (callbacks.firstPlay) {
        callbacks.firstPlay()
      }
    }
    firstplay.value = true
    stopTimer()
    startTimer(playerNode)
  }

  const endPlay = () => {
    timeupdate()
    stopTimer()
  }

  const ended = () => {
    if (callbacks.ended) {
      callbacks.ended()
    }
  }

  function playlistItemChanged () {
    if (player.value) {
      if (callbacks.playlistItemChanged) {
        const index = Number(player.value.api('playlist_id'))
        callbacks.playlistItemChanged(index)
      }
    }
  }

  function updateVideoProgress (time) {
    if (player.value) {
      const progress = (Number(time.info) / Number(player.value.api('duration'))) * 100
      if (callbacks.progress && !isNaN(progress)) {
        callbacks.progress(progress)
      }
    }
  }

  function updateSpeed (speed) {
    if (callbacks.updateSpeed) {
      callbacks.updateSpeed(Number(speed.info))
    }
  }

  function change (id, autoPlayNextVideo = true) {
    if (playerReady.value && player.value) {
      stopTimer()
      if (Number(player.value.api('playlist_id')) === id) {
        return
      }

      player.value.api('find', `${id}`)

      if (autoplay.value && autoPlayNextVideo) {
        player.value.api('play')
      }
    }
  }

  function subtitleChanged (event) {
    const lsSubs = LocalStorage.get(STORE_KEY_PJS_SUBS)
    if ((!lsSubs || lsSubs === 'off') && event.info !== 'off') {
      Analytics.send({
        category: 'subtitles_activity',
        action: 'subtitles_on',
        label: event.info
      })
    } else if (event.info === 'off') {
      Analytics.send({
        category: 'subtitles_activity',
        action: 'subtitles_off'
      })
    } else {
      Analytics.send({
        category: 'subtitles_activity',
        action: 'subtitles_change',
        label: event.info
      })
    }
    LocalStorage.set(STORE_KEY_PJS_SUBS, event.info)
  }

  function autoNext () {
    autoplay.value = Number(player.value?.api('autonext'))

    LocalStorage.set(STORE_KEY_AUTONEXT, String(autoplay.value))
    Swal.fire({
      icon: 'success',
      title: i18n.global.t(`player.autoNextSwitch.${autoplay.value ? 'enabled' : 'disabled'}`),
      timer: 2000,
      showConfirmButton: false
    })
  }

  function checkAutoNext () {
    autoplay.value = Number(player.value?.api('autonext'))
    window.autoPlaySwitchTip = i18n.global.t(`player.autoNextSwitch.${autoplay.value ? 'disable' : 'enable'}`)
  }

  function init ({ playerNode, lessonIndex, autoPlayNextVideo }) {
    playerNode.addEventListener('init', () => {
      playerReady.value = true
      const subtitle = LocalStorage.get(STORE_KEY_PJS_SUBS)
      if (subtitle && subtitle === 'off') {
        player.value?.api('subtitle', -1)
      }
      checkAutoNext()

      if (lessonIndex) {
        change(lessonIndex, autoPlayNextVideo)
      }
    })
    playerNode.addEventListener('play', () => startPlay(playerNode))
    playerNode.addEventListener('pause', endPlay)
    playerNode.addEventListener('error', endPlay)
    playerNode.addEventListener('end', ended)
    playerNode.addEventListener('new', playlistItemChanged)
    playerNode.addEventListener('time', updateVideoProgress)
    playerNode.addEventListener('speed', updateSpeed)
    playerNode.addEventListener('subtitle', subtitleChanged)
    playerNode.addEventListener('api:autonext,0/1', autoNext)
  }

  return {
    playerReady,
    init,
    change
  }
}

export default function usePlayer ({ rawVideos, targetId, wid, callbacks, lessonIndex, autoPlayNextVideo }: UsePlayer) {
  const player = ref<Playerjs | undefined>(undefined)
  const playerId = computed(() => targetId || 'html5player')
  const mq = useMq()
  const isDesktop = computed(() => mq.desktop)

  const initEvents = useInitEvents({ player, callbacks })

  function checkPlayerNode () {
    return !!document.getElementById(playerId.value)
  }

  function initScript () {
    if (window.Playerjs) {
      return
    }

    return new Promise(resolve => {
      const script = document.createElement('script')

      if (process.env.VUE_APP_PLAYERJS_CDN) {
        script.setAttribute('src', process.env.VUE_APP_PLAYERJS_CDN)
      } else {
        Logger.error('Ошибка, переменная process.env.VUE_APP_PLAYERJS_CDN не определена')
      }
      script.onload = resolve
      document.head.appendChild(script)
    })
  }

  function getPlaylist (videos: readonly DayLesson[]) {
    return videos.map((l, id) => {
      const poster = l.video.preview || ''
      const file = getUrlWithAuthParams(l.video.m3u8 || '') // process.env.NODE_ENV === 'development' ? getUrlWithAuthParams(l.video.m3u8 || '') : l.video.m3u8 || ''
      let subtitle = ''
      if (l.video.subs.length) {
        const ss: string[] = []
        l.video.subs.forEach(({ url, label }) => {
          const urlInstance = urlParse(getUrlWithAuthParams(getBaseUrl(url)), true)
          ss.push(`[${label}]${urlInstance.toString()}`)
        })
        subtitle = ss.join(',')
      }
      return {
        id: `${id}`,
        title: `<div class="playlist_item" data-id="id"><div class="playlist_title">${l.lesson.title}</div></div>`,
        poster,
        file,
        subtitle,

        default_subtitle: Locale.get() === 'ru' ? 'Русский' : 'English'
      }
    })
  }

  function destroyPlayer () {
    if (player.value) {
      player.value.api('destroy')
      player.value = undefined
    }
  }

  function playPlayer () {
    player.value?.api('play')
  }

  function mutePlayer (mute: boolean) {
    player.value?.api('muted', mute)
    if (mute) {
      player.value?.api('mute')
      player.value?.api('volume', 0)
    } else {
      player.value?.api('unmute')
      player.value?.api('volume', 1)
    }
  }

  function initPlayer () {
    destroyPlayer()
    const playlist = getPlaylist(rawVideos.value)

    if (window.Playerjs) {
      player.value = new window.Playerjs({
        id: playerId.value,
        wid: wid || null,
        file: playlist,
        lang: Locale.get(),
        rename_audio: {
          eng: 'English',
          rus: 'Русский',
          spa: 'Español'
        },
        hlsconfig: {
          firstLevel: -1,
          startLevel: -1
        },
        autonext: LocalStorage.get(STORE_KEY_AUTONEXT) || 1
      })

      if (isDesktop.value) {
        player.value.api('design', 2)
      } else {
        player.value.api('design', 1)
      }
    }
  }

  async function init () {
    await initScript()
    await initPlayer()
    if (checkPlayerNode()) {
      await initEvents.init({
        playerNode: document.getElementById(playerId.value),
        lessonIndex: lessonIndex.value,
        autoPlayNextVideo: autoPlayNextVideo.value
      })
    }
  }

  onBeforeUnmount(() => {
    destroyPlayer()
  })

  return {
    player,
    playerId,
    init,
    change: initEvents.change,
    playerReady: initEvents.playerReady,
    checkPlayerNode,
    initPlayer,
    playPlayer,
    mutePlayer,
    destroyPlayer
  }
}
